import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PurchaseOrder from "@/views/PurchaseOrders/PurchaseOrder";
import OuterView from "@/views/OuterView";
import PurchaseOrderList from "@/views/PurchaseOrders/PurchaseOrderList";
import ClientBookingForm from "@/views/CustomerBookingForm/ClientBookingForm";
import CustomerList from "@/views/CustomerBookingForm/CustomerList";
import OrderList from "@/views/CustomerBookingForm/OrderList";
import SettingsView from "@/views/Settings/SettingsView";
import DefaultScheduleList from "@/views/Settings/DefaultScheduleList";
import VendorList from "@/views/Settings/ContactList/VendorList";
import ContactsCustomerList from "@/views/Settings/ContactList/ContactsCustomerList";
import ContactsCustomer from "@/views/Settings/ContactList/ContactsCustomer";
import VendorContactList from "@/views/Settings/ContactList/VendorContactList";
import LoadConfirmation from "@/views/LoadConfirmation/LoadConfirmation";
import LoadConfirmationList from "@/views/LoadConfirmation/LoadConfirmationList";
import LoadConfirmationOrderList from "@/views/LoadConfirmation/LoadConfirmationOrderList";
import FailedEmailList from "@/views/Emails/FailedEmailList";
import LocationTemplateView from "@/views/TemplateManager/LocationTemplateView";
import AddLocationTemplate from "@/views/TemplateManager/AddLocationTemplate";
import LocationTemplateUpdate from "@/views/TemplateManager/LocationTemplateUpdate";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: OuterView,
        children: [
            {
                path: '/home',
                name: 'home',
                component: HomeView
            },
            {
                path: '/PurchaseOrder',
                name: 'po-list',
                component: PurchaseOrderList
            },
            {
                path: '/PurchaseOrder/:StartDate/:EndDate',
                name: 'po-list-date',
                component: PurchaseOrderList
            },
            {
                path: '/PurchaseOrder/:Vendor/:StartDate/:EndDate',
                name: 'po',
                component: PurchaseOrder
            },
            {
                path: '/ClientBooking',
                name: 'clientBookingForm-list',
                component: CustomerList
            },
            {
                path: '/ClientBooking/:StartDate/:EndDate',
                name: 'clientBookingForm-list-date',
                component: CustomerList
            },
            {
                path: '/ClientBooking/:Customer/:StartDate/:EndDate',
                name: 'clientBookingOrders',
                component: OrderList
            },
            {
                path: '/ClientBooking/:Customer/:order/:StartDate/:EndDate',
                name: 'clientBookingForm',
                component: ClientBookingForm
            },
            {
                path: '/LoadConfirmation',
                name: 'loadConfirmation-list',
                component: LoadConfirmationList
            },
            {
                path: '/LoadConfirmation/:StartDate/:EndDate',
                name: 'loadConfirmation-list-date',
                component: LoadConfirmationList
            },
            {
                path: '/LoadConfirmation/:Vendor/:StartDate/:EndDate',
                name: 'loadConfirmationOrders',
                component: LoadConfirmationOrderList
            },
            {
                path: '/LoadConfirmation/:Vendor/:order/:StartDate/:EndDate',
                name: 'loadConfirmation',
                component: LoadConfirmation
            },
            {
                path: '/Settings',
                name: 'settings',
                component: SettingsView
            },
            {
                path: '/Settings/Schedules',
                name: 'scheduleSettings',
                component: DefaultScheduleList
            },
            {
                path: '/Contacts/Customers',
                name: 'customerList',
                component: ContactsCustomerList
            },
            {
                path: '/Contacts/Customers/:Customer',
                name: 'contactsCustomer',
                component: ContactsCustomer
            },
            {
                path: '/Contacts/Vendors',
                name: 'vendorList',
                component: VendorList
            },
            {
                path: '/Emails/Manage',
                name: 'failedEmailList',
                component: FailedEmailList
            },
            {
                path: '/Contacts/Vendors/:Vendor',
                name: 'vendorContactList',
                component: VendorContactList
            },
            {
                path: '/TemplateManager/View',
                name: 'locationTemplateView',
                component: LocationTemplateView
            },
            {
                path: '/TemplateManager/Create',
                name: 'addLocationTemplate',
                component: AddLocationTemplate
            },

            {
                path: '/TemplateManager/Update',
                name: 'locationTemplateUpdate',
                component: LocationTemplateUpdate
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
