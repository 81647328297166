<template>
  <div class="page shaddow "
        v-if="cbf.client !== undefined">
    <b-row class="header-text-left">
      <!--        Banner-->
      <b-col cols="3"
             class="logo-box column ps-2">
        <!--            Image-->
        <img class="logo"
             src="../../assets/lcslogo.png"
             alt="LCS Logo">
        <!--        <img class="header-image" alt="LCS Header" src="data:image/png;base64,@Base64Image.Logo">-->
      </b-col>
      <b-col cols="3"
             class="column ps-4">
        <!--            Info 1-->
        <h2>{{ cbf.client.name }}</h2>
        <H4>{{ cbf.pickupLoc.name }}</H4>
        <H4>{{ cbf.dropOffLoc.name }}</H4>
      </b-col>
      <b-col cols="3"
             class="column mt-2 ps-4">
        <!--            Info 2-->
        <h2>Customer Order</h2>
        <h4>{{ cbf.purchaseOrderNumber }}</h4>
        <h4>{{ cbf.dateTime }}</h4>
        <h2 v-if="generatedDateTime">Date Generated</h2>
        <h4 v-if="generatedDateTime">{{ generatedDateTime }}</h4>
      </b-col>
      <b-col cols="3"
             class="qr-right">
        <img class="qr-img"
             :src="'data:image/png;base64,' + cbf.qrBase64" />
      </b-col>
    </b-row>
    <b-row>
      <table class="">
        <tr>
          <th>No.</th>
          <th>Fleet No.</th>
          <th>Truck Registration</th>
          <th>1st Trailer</th>
          <th>2nd Trailer</th>
          <th>Name & Surname</th>
          <th>ID Number</th>
          <th>Planning Status</th>
          <th>Update</th>
        </tr>
        <tr v-for="item of this.cbf.clientBookingItems"
            v-bind:key="item.no.toString()">
          <td>{{ item.no }}</td>
          <td>{{ item.fleetNo }}</td>
          <td class ="narrowvehicle">{{ item.truckReg }}</td>
          <td>{{ item.trailer1 }}</td>
          <td>{{ item.trailer2 }}</td>
          <td>{{ item.fullName }}</td>
          <td>{{ item.idNumber }}</td>
          <td class ="narrowPlanningStatus">{{ item.planningStatus }}</td>
          <td class = "updateColumn">{{ item.update }}</td>
        </tr>
      </table>
    </b-row>
    <div class="row button-List">
      <b-button id="EmailActions"> Email Actions <b-icon icon="triangle-fill" class="triangle-icon"></b-icon></b-button>
      <b-tooltip target="EmailActions">
        <b-button @click="GetRecipients"  class="tooltip-button mb-1" :disabled="isDisableButton">View Recipients</b-button>
        <b-button v-b-modal.AddEmailModal class="tooltip-button mb-1" :disabled="isDisableButton">Add Email address and Send</b-button>
        <b-button v-b-modal.CustomEmailModal class="tooltip-button mb-1" :disabled="isDisableButton">Send To Custom Email Address</b-button>
        <b-button @click="SendLCS" class="tooltip-button mb-1" :disabled="isDisableButton">Send To LCS User </b-button>
          <b-button @click="Accept" class="tooltip-button" :disabled="isDisableButton">{{!isDisableButton ? "Accept" : ""}}<b-spinner v-if="isDisableButton"></b-spinner></b-button>
      </b-tooltip> &nbsp; <b-button id="RejectOrder" v-b-modal.RejectionModal :disabled="isDisableButton">Reject</b-button> &nbsp; <b-button id="ExportOrder" @click="Export">Export (PDF)</b-button>
    </div>

    <b-modal ref="viewRecipientsModal"
             title="Recipients"
             @ok="ClearModals"
             ok-only
             ok-title="Close">
             <div>
              <b-list-group v-if="recipients.length > 0">
                <b-list-group-item 
                  v-for="(recipient, index) in recipients" 
                  :key="index">
                  <strong>Name:</strong> {{ recipient.name }}, <strong>Email:</strong> {{ recipient.email }}
                </b-list-group-item>
              </b-list-group>

              <div v-else>
                <p>No recipients to display.</p>
              </div>
            </div>
    </b-modal>

    <!--    Add Email Modal-->
    <b-modal id="AddEmailModal"
             title="Custom Email"
             @ok="AddEmail"
             @hide="ClearModals">
      <b-input-group prepend="Full Name">
        <b-form-input v-model="modals.name"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Email Address">
        <b-form-input v-model="modals.customEmail"></b-form-input>
      </b-input-group>
    </b-modal>
    <!--    Custom Email Modal-->
    <b-modal id="CustomEmailModal"
             title="Custom Email"
             @ok="SendCustom"
             @hide="ClearModals">
      <b-input-group prepend="Full Name">
        <b-form-input v-model="modals.name"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Email Address">
        <b-form-input v-model="modals.customEmail"></b-form-input>
      </b-input-group>
    </b-modal>
    <!--    Rejection Modal-->
    <b-modal id="RejectionModal"
             title="Rejection"
             @ok="Reject"
             @hide="ClearModals">
      <H2>Please enter a reason for your rejection</H2>
      <!--      <b-input-group prepend="Reason">-->
      <b-form-input v-model="modals.rejection"></b-form-input>
      <!--      </b-input-group>-->
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";

export default {
  name: "ClientBookingForm",
  data: () => ({
    customerKey: Number,
    cbf: {},
    startDate: "",
    endDate: "",
    meta: {},
    modals: {
      rejection: null,
      customEmail: null
    },
    isDisableButton: false,
    recipients: []
  }),
  created() {
    this.customerKey = this.$route.params["Customer"]
    this.startDate = this.$route.params["StartDate"]
    this.endDate = this.$route.params["EndDate"]
    this.orderId = this.$route.params["order"]
    // const date = new Date(this.startDate)

    const dateNavigation = this.startDate === this.endDate ? this.startDate : this.startDate + " to " + this.endDate;

    api.getCustomerBookingForm(this.orderId, this.startDate, this.endDate, (data) => {
      this.cbf = data

      api.getCustomerBookingFormMeta(this.orderId, this.startDate, this.endDate, (data) => {
        this.meta = data
      },
        () => this.makeToast("Customer Booking Form", "Failed to get document Meta", "danger"))
      this.setBreadcrumb([
        {
          text: 'Documentations',
        },
        {
          text: 'Customer Booking',
          to: { name: 'clientBookingForm-list' },
        },
        {
          text: 'Date : ' + dateNavigation,
          to: { name: 'clientBookingForm-list-date', params: { StartDate: this.startDate, EndDate: this.endDate } },
        },
        {
          text: this.cbf.client.name,
          to: { name: 'clientBookingOrders', params: { Customer: this.cbf.client.id, StartDate: this.startDate, EndDate: this.endDate } },
        },
        {
          text: 'Order: ' + this.cbf.order,
          active: true
        }

      ])
    }, () => this.makeToast("Customer Booking Form", "Failed to get Customer Booking Form", "danger"))

  },
  computed: {
    generatedDateTime() {
      return this.meta?.date ? this.meta.date.replace('T', ' ').substring(0, 19) : '';
    }
  },
  methods: {
    ...mapMutations("breadcrumbService", ["setBreadcrumb"]),
    Accept() {
      this.isDisableButton = true;
      api.SendCbfToCustomer(this.meta,
        () => {
          this.makeToast("Customer Booking Form", "Document sent to " + this.cbf.client.name)
          this.isDisableButton = false;
        }, () => {
          this.makeToast("Customer Booking Form", "Failed to send Document to " + this.cbf.client.name)
          this.isDisableButton = false;
        },
      )
    },
    GetRecipients() {
      this.isDisableButton = true;
      api.GetClientBookingFormRecipients(this.meta,
        (data) => {
          this.recipients = data;
          this.isDisableButton = false;
          this.$refs.viewRecipientsModal.show();
        }, () => {
          this.makeToast("Customer Booking Form", "Failed to get recipients for " + this.cbf.client.name)
          this.isDisableButton = false;
        },
      )
    },
    SendCustom() {
      var data = {
        "document": this.meta,
        "person": {
          "name": this.modals.name,
          "landline": "",
          "cellPhone": "",
          "email": this.modals.customEmail
        }
      }
      api.SendCbfToCustom(data,
        () => {
          this.makeToast("Customer Booking Form", "Document sent to " + this.cbf.client.name)
        }, () => {
          this.makeToast("Customer Booking Form", "Failed to send Document to " + this.cbf.client.name)
        },
      )
    },
    AddEmail() {
      var data = {
        "documentType": "ClientBookinForm",
        "entityId": this.meta.entityId,
        "person": {
          "name": this.modals.name,
          "email": this.modals.customEmail
        }

      }
      api.AddClientEmailAddress(data,
        () => {
          this.makeToast("Customer Booking Form", "Document sent to " + this.cbf.client.name)
          this.Accept();
        }, () => {
          this.makeToast("Customer Booking Form", "Failed to send Document to " + this.cbf.client.name)
        },
      )
    },
    SendLCS() {
      this.meta.reason = this.modals.rejection
      api.SendCbfToLCS(this.meta,
        () => {
          this.makeToast("Customer Booking Form", "Document sent to " + this.cbf.client.name)
        }, () => {
          this.makeToast("Customer Booking Form", "Failed to send Document to " + this.cbf.client.name)
        },
      )
    },
    Reject() {
      api.RejectCbf(this.meta,
        () => {
          this.makeToast("Customer Booking Form", "Document has been reported")
        }, () => {
          this.makeToast("Customer Booking Form", "Failed report Document")
        },
      )
    },
    Export() {
      api.getCustomerBookingFormPDF(this.meta.id,
        (data) => {
          // Let's create a link in the document that we'll
          // programmatically 'click'.
          const link = document.createElement('a');

          // Tell the browser to associate the response data to
          // the URL of the link we created above.
          link.href = window.URL.createObjectURL(
            new Blob([data])
          );

          // Tell the browser to download, not render, the file.
          link.setAttribute('download', 'Customer Booking Form - ' + this.cbf.client.code + '.pdf');

          // Place the link in the DOM.
          document.body.appendChild(link);

          // Make the magic happen!
          link.click();
        }, () => {
          this.makeToast("Customer Booking Form", "Failed to send Document to " + this.cbf.client.name)
        },
      )
    },
    ClearModals() {
      this.modals = {
        rejection: null,
        customEmail: null
      }
    }
  }
}
</script>

<style scoped lang="scss">
* {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
}

h1 {
  font-size: 24px;
}

h2 {
  color: rgb(47, 143, 188);
  font-size: 17px;
  margin: 14.11px 0;
}

h3 {
  font-size: 13px;
  margin-bottom: 0rem;
}

h4 {
  font-size: 16px;
  color: rgb(158, 158, 158);
  font-weight: 300;
  margin-bottom: 21.28px;
}

p {
  margin-bottom: 0rem;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
}

td,
th {
  border: 1px solid black;
  text-align: left;
  padding: 5px;
  height: 10px;
}

th {
  background-color: rgb(217, 217, 217);
}


li {
  margin-top: .75rem;
}

.red-text {
  color: red;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: 700;
}

.email {
  color: rgb(76, 76, 204);
  text-decoration: underline;
}

.underline {
  text-decoration: underline;
}

.signature-paragraph {
  border-top: 2px solid black;
  margin-top: 5rem;
  margin-right: 3rem;
  font-weight: 700;
}

table.transport-table tr th {
  background-color: rgb(32, 32, 112);
  color: white;
}

.col-4 {
  column-count: 4;
}

.column {
  float: left;
  /*width: 50%;*/
}

.row {
  display: flex !important;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.m-1 {
  margin: 0.5rem;
}

.m-2 {
  margin: 1rem;
}

.mt-0 {
  margin-top: 0rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.mb-1 {
  margin-bottom: 0.5rem
}

.ps-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.ps-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.page {
  background-color: whitesmoke;
  width: 1200px;
  padding: 50px 100px;
  margin: 0 auto !important;
}

.logo {
  width: 210px;
}

.logo-box {
  margin-bottom: 0.5rem !important;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
}

.img-columns {
  text-align: center;
}

.header-text-left {
  color: #A4B0B6;
  font-size: 12px;
  text-align: left;
}

.header-image {
  width: 294px;
  position: relative;
  left: -150px;
  top: -125px;
}

.button-List {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 54px 55px, rgba(0, 0, 0, 0.24) 0px -12px 30px, rgba(0, 0, 0, 0.24) 0px 4px 6px, rgba(0, 0, 0, 0.34) 0px 12px 13px, rgba(0, 0, 0, 0.20) 0px -3px 5px;
}

.page-border {
  border: 2px black solid;
}
.updateColumn{
  width: 170px;
}
.narrowvehicle
{
  width: 50px;
}
.narrowPlanningStatus
{
  width: 50px;
}


</style>