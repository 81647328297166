import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from "./api";
import store from './store'
import {BootstrapVue, IconsPlugin} from "bootstrap-vue";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './globa-style.scss'

// import Popper from 'popper.js'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
// Vue.use(Popper)

Vue.mixin({
    methods: {
        makeToast: function (title, message, variant = null) {
            this.$bvToast.toast(`${message}`, {
                title: title,
                variant: variant,
                autoHideDelay: 5000,
                appendToast: false,
                noCloseButton: true
            })
        },
    }
})

let api_url = "api_url"
if (process.env.NODE_ENV === "development")
{
    api_url = "api_url_dev"
}
fetch('/config.json')
    .then(res => res.json())
    .then(config => {
        api.Url = config[api_url],
            new Vue({
                data: () => ({
                    urlConfig: config
                }),
                router,
                store,
                render: h => h(App)
            }).$mount('#app')
    })
