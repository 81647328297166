<template>
  <div class="document-generation"
       id="PurchaseOrderList">
    <div class="page">
      <b-row>
        <H1> Customer Booking Form </H1>
      </b-row>
      <b-row>
        <H2> Customer List</H2>
      </b-row>
      <b-row>
        <date-range-picker :defaultDate="defaultDate"
                           @onDateSelected="onDateSelected" />
      </b-row>
      <div v-if="!loading && customerList.length != 0">
        <b-row>
          <b-input-group class="mb-3 w-100"
                         prepend="Customer">
            <b-form-input type="text"
                          v-model="searchInput"></b-form-input>
            <b-input-group-append>
              <b-button @click="search">Search <b-icon-search />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-row>
        <b-row>
          <b-col class="p-0">
            <b-list-group v-for="customer in filteredList"
                          :key="'Customer' + customer.id">
              <b-list-group-item :to="{ name: 'clientBookingOrders', params: { Customer: customer.id, StartDate: startDate, EndDate: endDate } }" >
                {{ customer.name }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </div>
      <b-row v-if="customerList.length == 0 && !loading">
        <b-col class="p-0">
          <b-list-group>
            <b-list-group-item>No customer bookings for this date</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <div v-if="loading"
           class="d-flex justify-content-center pt-4">
        <b-row>
          <b-spinner></b-spinner>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";
import DateRangePicker from '../../components/DateRangePicker.vue'
import sortClients from "@/sortAlgorithm";

export default {
  name: "CustomerList",
  components: {
    DateRangePicker
  },
  data: () => ({
    startDate: "",
    endDate: "",
    defaultDate: [],
    customerList: [],
    loading: false,
    searchInput: "",
    filteredList: []
  }),
  methods: {
    ...mapMutations("breadcrumbService", ["setBreadcrumb"]),
    onDateSelected(dateSelected) {
      this.startDate = dateSelected.startDate;
      this.endDate = dateSelected.endDate;

      if (this.startDate && this.endDate) {
        this.$router.push({ path: `/ClientBooking/${this.startDate}/${this.endDate}` })
      }

    },
    search() {
      console.log(this.searchInput)

      this.filteredList = this.customerList.filter(x => x.name.toLowerCase().includes(this.searchInput.toLowerCase()))
    },
    statusIcon(status) {
      if (status === "accepted") {
        return "patch-check-fill"
      }
      if (status === "denied") {
        return "patch-exclamation-fill"
      }
      return "patch-question-fill"
    },
    statusIconVariant(status) {
      if (status === "accepted") {
        return "success"
      }
      if (status === "denied") {
        return "warning"
      }
      return "info"
    },
  },
  created() {
    this.startDate = this.$route.params["StartDate"];
    this.endDate = this.$route.params["EndDate"];

    if (this.startDate && this.endDate) {
      this.defaultDate = [this.startDate, this.endDate];

      const dateNavigation = this.startDate === this.endDate ? this.startDate : this.startDate + " to " + this.endDate;

      this.setBreadcrumb([
        {
          text: 'Documentations',

        },
        {
          text: 'Customer Booking',
          to: { name: 'clientBookingForm-list' },
        },
        {
          text: 'Date : ' + dateNavigation,
          active: true
        }

      ])

      this.loading = true;
      api.getCustomerListForDate(this.startDate, this.endDate, (data) => {
        this.customerList = data
        this.filteredList = this.customerList
        sortClients(this.filteredList)
        this.loading = false;
      },
        (e) => {
          console.error(e)
          this.makeToast("Customer List", "Failed to load Customer list for date", "warning")
          this.loading = false;
        })
    } else {
      this.setBreadcrumb([
        {
          text: 'Documentations',

        },
        {
          text: 'Client Booking Forms',
          active: true,
        },

      ])
    }

  }

}
</script>

<style scoped>
.page {
  background-color: whitesmoke;
  width: 1200px;
  padding: 125px 150px;
  margin: 0 auto;
}
</style>