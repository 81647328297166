import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseUrl: '',

  },
  mutations: {

    //set requests and the likes
    setApiUrl: (state, payload) => {
      state.baseUrl = payload
      localStorage.setItem('apiUrl', payload)
    },

  },
  actions: {
    //log in screen
 
  },
  modules: {
  }
})
