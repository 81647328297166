<template>
  <div id="PurchaseOrder"
       class="m-0">
    <div class="row page shadow"
         v-if="PO.vendor.name !== undefined">
      <div class="row">
        <div class="column"
             style="height: 10px">
          <img class="header-image"
               alt="LCS Header"
               src="../../assets/Header_Logo.png">
        </div>
        <div class="column header-text-left">
          <p class="mt-0">c/o Stasie & Meyer Street, Heidelberg, Gauteng 1441</p>
          <p class="mt-0">PO Box 1680, Heidelberg, Gauteng 1438</p>
          <p class="mt-0">p: 086 152 7476</p>
          <p class="mt-0">e: support@lcsgroup.co.za</p>
          <p class="mt-0">w: www.lcsgroup.co.za</p>
        </div>
      </div>
      <div class="row">
        <h4>LCS Logistics (Pty) Ltd</h4>
        <h1 class="mb-0">Transport Purchase Order Details</h1>
        <hr class="mt-0">
        <div class="row">
          <div class="column">
            <h2 class="mb-1">Contact Person</h2>
            <p class="bold mt-0">{{ PO.contactPerson.name }}</p>
            <p class="mt-0">{{ PO.contactPerson.landline }}</p>
            <p class="mt-0">{{ PO.contactPerson.cellPhone }}</p>
            <p class="mt-0 email">{{ PO.contactPerson.email }}</p>
            <p><span class="bold">Company Reg No:</span> {{ PO.lcsDetails.companyRegNo }}</p>
            <p class="mt-0"><span class="bold">VAT number:</span> {{ PO.lcsDetails.vatNo }}</p>
          </div>
          <div class="column text-right">
            <h2 class="red-text">Please Address All Invoices &<br> Original Documents To:</h2>
            <p class="mt-0">{{ PO.lcsDetails.address1 }}</p>
            <p class="mt-0">{{ PO.lcsDetails.address2 }}</p>
            <p class="mt-0">{{ PO.lcsDetails.city }}</p>
            <p class="mt-0">{{ PO.lcsDetails.province }}</p>
            <p class="mt-0">{{ PO.lcsDetails.postalCode }}</p>
            <p class="mt-0"><span class="bold">Tel: </span>{{ PO.lcsDetails.landline }}</p>
          </div>
        </div>
        <div class="mb-1">
          <h2 class="mb-1">Contractor Company Details</h2>
          <p class="mt-0"><span class="bold">Name: </span>{{ PO.vendor.name }}</p>
          <p class="mt-0"><span class="bold">Tel: </span>{{ PO.vendor.tel }}</p>
          <p class="mt-0"><span class="bold">Email: </span><span class="email">{{ PO.vendor.email }}</span></p>
        </div>
        <table>
          <tr>
            <th class="text-center">Validity</th>
            <th class="text-center">Date Issued</th>
            <th class="text-center">PO Requisitioner</th>
          </tr>
          <tr>
            <td class="text-center" >{{ PO.validityFrom }}</td>
            <td class="text-center">{{ PO.dateIssued }}</td>
            <td class="text-center email">{{ PO.requisitioner.name }}</td>
          </tr>
        </table>
        <h3>Transport Rates</h3>
        <table class="transport-table">
          <tr>
            <th class="text-center">Purchase Order Number</th>
            <th class="text-center">Supplier Details</th>
            <th class="text-center">Demander Details</th>
            <th class="text-center">Material</th>
            <th class="text-center">Invoice Tons</th>
            <th class="text-center">Rate Period</th>
            <th class="text-center">Rate {{ PO.monthYear }}</th>
          </tr>
          <tr v-for="item of this.PO.serviceItems"
              v-bind:key="item.purchaseOrderNumber">
            <td>{{ item.purchaseOrderNumber }}</td>
            <td>{{ item.pickupLocation }}</td>
            <td>{{ item.dropoffLocation }}</td>
            <td>{{ item.material }}</td>
            <td>{{ item.invoiceTons }}</td>
            <td>{{ item.ratePeriod }}</td>
            <td>{{ item.rate }}</td>
          </tr>
        </table>
        <h2 class="red-text">All Transport Rates Are VAT Exclusive</h2>
        <table>
          <tr>
            <td class="bold">Acceptance of Order</td>
            <td>By transporting the goods specified in the Purchase Order, Supplier accepts the Purchase Order and agrees to be bound by the terms and conditions provided herein </td>
          </tr>
          <tr>
            <td class="bold">Order Number</td>
            <td>Order numbers will be supplied by LCS Logistics monthly. Contact no: <span class="bold">061 340 4982</span></td>
          </tr>
          <tr>
            <td class="bold">Goods-in-Transit Insurance Cover</td>
            <td> Comprehensive Goods in Transit (GIT) insurance cover is required, to the minimum value as indicated below: <ul>
                <li class="bold">All Loads: R1 000 000 Per Load</li>
                <li class="bold red-text">All Other Loads - Contractor to confirm with LCS Logistics prior to loading </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="bold underline">Note</td>
            <td>
              <ul>
                <li>The cost of GIT is for the account of the Contractor and therefore included in the transport tariff </li>
                <li>All costs arising from the principal client will be directly passed on and recovered from the Contractor. Copies of original invoices will be provided, and charges are non-negotiable. </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="bold">Delivery Requirements</td>
            <td> Contractors must deliver all collected loads within 24 hours of collection. <p class="red-text">LCS Logistics must immediately be notified in writing and by no later than 8 hours after the event, of any non-delivery</p>
            </td>
          </tr>
          <tr>
            <td class="bold">Spillage Recovery</td>
            <td> Accidental spillages must be cleaned up within a reasonable timeframe, at the Contractors own cost, should any spillage occur during transport or whilst the product is under the Contractors control. <p class="red-text">LCS Logistics must immediately be notified in writing <span class="bold underline">and by no later than 8 hours after the event,</span> of an incident/accident occurring.</p>
            </td>
          </tr>
          <tr>
            <td class="bold">Safety Requirements</td>
            <td> The Contractor will be compelled to supply all drivers with PPE (Personal Protective Equipment), i.e., overalls (including a jacket, not only trousers), hard-hat, safety shoes/boots, safety glasses, hearing protection, etc. &nbsp; Drivers MUST always wear their safety belts at the loading and off-loading sites when driving <p class="bold red-text">PLEASE TAKE NOTE THAT ANY DRIVER NOT EQUIPPED WITH PPE WILL NOT BE ALLOWED TO ENTER THE LOADING OR OFFLOADING SITE</p>
            </td>
          </tr>
          <tr>
            <td class="bold red-text">Payment Terms</td>
            <td class="bold red-text">ALL ROUTES</td>
          </tr>
        </table>
        <table>
          <tr>
            <th class="text-center">Period</th>
            <th class="text-center">Transport Period</th>
            <th class="text-center">Cut-off Date</th>
            <th class="text-center">Payment Date</th>
          </tr>
          <tr>
            <td class="text-center red-text bold"
                rowspan="2">Example</td>
            <td class="text-center bold">1st of the First month to the 15th of the First month</td>
            <td class="text-center">
              <span class="bold">15th of the First month</span> (Or on the first business day thereafter should the 15th of the month fall on a weekend or Public Holiday)
            </td>
            <td class="text-center">
              <span class="bold">On the 1st of the Third month</span> (Or on the first business day thereafter should the 1st day of the month fall on a weekend or Public Holiday)
            </td>
          </tr>
          <tr>
            <td colspan="3"
                class="img-columns">
              <img alt="Vue logo"
                   src="../../assets/LCS_POD_IMG1.png">
            </td>
            <!--        <td class="text-center" colspan="3">Lorem</td>-->
          </tr>
          <tr>
            <td class="text-center red-text bold"
                rowspan="2">Example</td>
            <td class="text-center bold">16th of the First month to the Last day of the First month</td>
            <td class="text-center">
              <span class="bold">Last Day of the First month</span> (Or on the first business day thereafter should the Last day of the month fall on a weekend or Public Holiday)
            </td>
            <td class="text-center">
              <span class="bold">On the 16th of the Third month</span> (Or on the first business day thereafter should the 16th day of the month fall on a weekend or Public Holiday)
            </td>
          </tr>
          <tr>
            <td colspan="3"
                class="img-columns">
              <img alt="Vue logo"
                   src="../../assets/LCS_POD_IMG2.png">
            </td>
            <!--        <td class="text-center" colspan="3">Lorem</td>-->
          </tr>
        </table>
        <h2 class="red-text">Conditions of Payment:</h2>
        <ul>
          <li>Payment will be made on the next working day, should the original payment date fall on a weekend or public holiday </li>
          <li>Please submit all Signed Purchase Orders together with Invoices at the end of each month - <span class="bold red-text">No Purchase Order, No Payment</span></li>
          <li>Payment will only be made if the relevant signed weighbridge certificates, together with all other relevant documentation is delivered to: <span class="bold red-text">LCS Logistics, 4 Meyer Street, Heidelberg, Gauteng 1441.</span>
          </li>
          <li> For all <span class="bold red-text">Linked Route Combinations</span>, the Contractor shall complete a round trip in accordance with the terms set out hereunder <ul>
              <li>The Contractor shall only be entitled to payment once the round trip has been completed <span class="red-text">(i.e., both the outbound and inbound legs have been completed)</span>, as evidenced by all relevant documentation </li>
              <li>LCS Logistics shall instruct the Contractor prior to departure on the outbound / inbound trip as to the required and linked inbound / outbound trip </li>
              <li>In the event of the inbound / outbound trip becoming unavailable during the journey, then LCS shall instruct the Contractor as to an alternative inbound / outbound trip </li>
              <li>By accepting the outbound trip, the Contractor invariably agrees and undertakes to complete the inbound trip and vice versa. </li>
            </ul>
          </li>
        </ul>
        <div class="row">
          <div class="column">
            <p class="signature-paragraph">Contractor Signature</p>
            <p class="signature-paragraph">Designation</p>
          </div>
          <div class="column">
            <p class="signature-paragraph">Date</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row button-List">
      <b-button id="EmailActions"> Email Actions <b-icon icon="triangle-fill" class="triangle-icon"></b-icon></b-button>
      <b-tooltip target="EmailActions">
        <b-button @click="GetRecipients"  class="tooltip-button mb-1" :disabled="isDisableButton">View Recipients</b-button>
        <b-button v-b-modal.AddEmailModal class="tooltip-button mb-1" :disabled="isDisableButton">Add Email address and Send</b-button>
        <b-button v-b-modal.CustomEmailModal class="tooltip-button mb-1" :disabled="isDisableButton">Send To Custom Email Address</b-button>
        <b-button @click="SendLCS" class="tooltip-button mb-1" :disabled="isDisableButton">Send To LCS User </b-button>
          <b-button @click="Accept" class="tooltip-button" :disabled="isDisableButton">{{!isDisableButton ? "Accept" : ""}}<b-spinner v-if="isDisableButton"></b-spinner></b-button>
      </b-tooltip> &nbsp; <b-button id="RejectOrder" v-b-modal.RejectionModal :disabled="isDisableButton">Reject</b-button> &nbsp; <b-button id="ExportOrder" @click="Export">Export (PDF)</b-button>
    </div>

    <b-modal ref="viewRecipientsModal"
             title="Recipients"
             @ok="ClearModals"
             ok-only
             ok-title="Close">
             <div>
              <b-list-group v-if="recipients.length > 0">
                <b-list-group-item 
                  v-for="(recipient, index) in recipients" 
                  :key="index">
                  <strong>Name:</strong> {{ recipient.name }}, <strong>Email:</strong> {{ recipient.email }}
                </b-list-group-item>
              </b-list-group>

              <div v-else>
                <p>No recipients to display.</p>
              </div>
            </div>
    </b-modal>

    <!--    Add Email Modal-->
    <b-modal id="AddEmailModal"
             title="Custom Email"
             @ok="AddEmail"
             @hide="ClearModals">
      <b-input-group prepend="Full Name">
        <b-form-input v-model="modals.name"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Email Address">
        <b-form-input v-model="modals.customEmail"></b-form-input>
      </b-input-group>
    </b-modal>
    <!--    Custom Email Modal-->
    <b-modal id="CustomEmailModal"
             title="Custom Email"
             @ok="SendCustom"
             @hide="ClearModals">
      <b-input-group prepend="Full Name">
        <b-form-input v-model="modals.name"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Email Address">
        <b-form-input v-model="modals.customEmail"></b-form-input>
      </b-input-group>
    </b-modal>
    <!--    Rejection Modal-->
    <b-modal id="RejectionModal"
             title="Rejection"
             @ok="Reject"
             @hide="ClearModals">
      <H2>Please enter a reason for your rejection</H2>
      <!--      <b-input-group prepend="Reason">-->
      <b-form-input v-model="modals.rejection"></b-form-input>
      <!--      </b-input-group>-->
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
//import { formats } from "flatpickr/dist/utils/formatting";
import { mapMutations } from "vuex";

export default {
  name: "PurchaseOrder",
  data: () => ({
    vendorId: "",
    startDate: "",
    PO: {
      vendor: {},
      serviceItems: [],
      monthYear: "",
    },
    validityFrom: null,
    validityTo: null,
    dateIssued: null,
    contactPerson: {},
    requisitioner: {},
    lcsDetails: {},
    meta: {},
    modals: {
      rejection: null,
      customEmail: null
    },
    isDisableButton: false,
    recipients: []
  }),
  created() {
    this.vendorId = this.$route.params["Vendor"]
    this.startDate = this.$route.params["StartDate"]
    this.endDate = this.$route.params["EndDate"]

    if (this.startDate && this.endDate) {
      this.defaultDate = [this.startDate, this.endDate];

      // var vendor
      api.getPO(this.vendorId, this.startDate, this.endDate, (data) => {

        this.PO = data

      },
        () => this.makeToast("Purchase Order", "Failed to get Purchase order", "danger"))
      api.getPoMeta(this.vendorId, this.startDate, this.endDate, (data) => {

        this.meta = data
      },
        () => this.makeToast("Purchase Order", "Failed to get Purchase order meta", "danger"))


      api.getVendorByID(this.vendorId, (data) => {
        const dateNavigation = this.startDate === this.endDate ? this.startDate : this.startDate + " to " + this.endDate;

        this.PO.vendor = data;
        this.setBreadcrumb([
          {
            text: 'Documentations',

          },
          {
            text: 'Purchase Orders',
            to: { name: 'po-list' },
          }, {
            text: 'Date : ' + dateNavigation,
            to: { name: 'po-list-date', param: { StartDate: this.startDate, EndDate: this.endDate } }
          },
          {
            text: 'Vendor : ' + this.PO.vendor.name,
            active: true
          }

        ])
      }, () => {
        this.makeToast("Vendor", "Failed to get Vendor Details", "warning")
      })
    }
  },
  methods: {
    ...mapMutations("breadcrumbService", ["setBreadcrumb"]),
    Accept() {
      this.isDisableButton = true;
      api.SendPoToVendor(this.meta,
        () => {
          this.makeToast("Purchase Order", "Document sent to " + this.PO.vendor.name)
          this.isDisableButton = false;
        }, () => {
          this.makeToast("Purchase Order", "Failed to send Document to " + this.PO.vendor.name)
          this.isDisableButton = false;
        },
      )
    },
    GetRecipients() {
      this.isDisableButton = true;
      api.GetRecipients(this.meta,
        (data) => {
          this.recipients = data;
          this.isDisableButton = false;
          this.$refs.viewRecipientsModal.show();
        }, () => {
          this.makeToast("Purchase Order", "Failed to get recipients for " + this.PO.vendor.name)
          this.isDisableButton = false;
        },
      )
    },
    SendCustom() {
      var data = {
        "document": this.meta,
        "person": {
          "name": this.modals.name,
          "landline": "",
          "cellPhone": "",
          "email": this.modals.customEmail
        }
      }
      api.SendPoToCustom(data,
        () => {
          this.makeToast("Purchase Order", "Document sent to " + this.PO.vendor.name)
        }, () => {
          this.makeToast("Purchase Order", "Failed to send Document to " + this.PO.vendor.name)
        },
      )
    },
    AddEmail() {
      var data = {
        "documentType": "PurchaseOrderDocument",
        "entityId": this.vendorId,
        "person": {
          "name": this.modals.name,
          "email": this.modals.customEmail
        }

      }
      api.AddVendorEmailAddress(data,
        () => {
          this.makeToast("Purchase Order", "Document sent to " + this.PO.vendor.name)
          this.Accept();
        }, () => {
          this.makeToast("Purchase Order", "Failed to send Document to " + this.PO.vendor.name)
        },
      )
    },
    SendLCS() {
      api.SendPoToLCS(this.meta,
        () => {
          this.makeToast("Purchase Order", "Document sent to " + this.PO.vendor.name)
        }, () => {
          this.makeToast("Purchase Order", "Failed to send Document to " + this.PO.vendor.name)
        },
      )
    },
    Reject() {
      this.meta.reason = this.modals.rejection
      api.RejectPo(this.meta,
        () => {
          this.makeToast("Purchase Order", "Document has been reported")
        }, () => {
          this.makeToast("Purchase Order", "Failed report Document")
        },
      )
    },
    Export() {
      api.getPoPDF(this.meta.id,
        (data) => {
          // Let's create a link in the document that we'll
          // programmatically 'click'.
          const link = document.createElement('a');

          // Tell the browser to associate the response data to
          // the URL of the link we created above.
          link.href = window.URL.createObjectURL(
            new Blob([data])
          );

          // Tell the browser to download, not render, the file.
          link.setAttribute('download', 'Purchase Order - ' + this.PO.vendor.code + '.pdf');

          // Place the link in the DOM.
          document.body.appendChild(link);

          // Make the magic happen!
          link.click();
        }, () => {
          this.makeToast("Purchase Order", "Failed to send Document to " + this.PO.vendor.name)
        },
      )
    },
    ClearModals() {
      this.modals = {
        rejection: null,
        customEmail: null
      }
    }
  }
}
</script>

<style scoped>
#PurchaseOrder {
  text-align: left;

}

* {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
}

h1 {
  font-size: 24px;
}

h2 {
  color: rgb(47, 143, 188);
  font-size: 17px;
}

h3 {
  font-size: 13px;
  margin-bottom: 0rem;
}

h4 {
  font-size: 16px;
  color: rgb(158, 158, 158);
  font-weight: 300;
  margin-bottom: 0rem;
}

p {
  margin-bottom: 0rem;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
}

td,
th {
  border: 1px solid black;
  text-align: left;
  padding: 8px;
}

th {
  background-color: rgb(217, 217, 217);
}


li {
  margin-top: .75rem;
}

.red-text {
  color: red;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: 700;
}

.email {
  color: rgb(76, 76, 204);
  text-decoration: underline;
}

.underline {
  text-decoration: underline;
}

.signature-paragraph {
  border-top: 2px solid black;
  margin-top: 5rem;
  margin-right: 3rem;
  font-weight: 700;
}

table.transport-table tr th {
  background-color: rgb(32, 32, 112);
  color: white;
}

.column {
  float: left;
  width: 50%;
}

.row {
  display: block !important;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.mt-0 {
  margin-top: 0rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.mb-1 {
  margin-bottom: 0.5rem
}

.page {
  background-color: whitesmoke;
  width: 1200px;
  padding: 125px 150px;
  margin: 0 auto !important;
}

.img-columns {
  text-align: center;
}

.header-text-left {
  color: #A4B0B6;
  font-size: 12px;
  text-align: right;
}

.header-image {
  width: 294px;
  position: relative;
  left: -150px;
  top: -125px;
}

.button-List {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 54px 55px, rgba(0, 0, 0, 0.24) 0px -12px 30px, rgba(0, 0, 0, 0.24) 0px 4px 6px, rgba(0, 0, 0, 0.34) 0px 12px 13px, rgba(0, 0, 0, 0.20) 0px -3px 5px;
}
</style>