<template>
  <div class="document-generation"
       id="PurchaseOrderList">
    <div class="page">
      <div>
        <b-row>
          <H1>Contacts</H1>
        </b-row>
        <b-row>
          <b-col cols="12"
                 class="text-right p-0 mb-3">
            <b-button @click="addContact"><b-icon icon="plus"></b-icon> Add Contact</b-button>
          </b-col>
        </b-row>
        <b-row v-if="contacts.length > 0 && !loading">
          <b-col class="p-0">
            <b-list-group v-for="contact in contacts"
                          :key="'Customer' + contact">
              <b-list-group-item> Full Name: {{ contact.fullname }}, Email Address: {{ contact.emailAddress }}
                <span class="float-right">
                  <b-icon-eye @click ="viewpickUplocationlist(contact)" class ="mr-3"></b-icon-eye>
                  <b-icon-pencil @click="editContact(contact)"
                                 class="mr-3"></b-icon-pencil>
                  <b-icon-trash @click="confirmDeleteContact(contact)"></b-icon-trash>
                </span>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row v-if="contacts.length == 0 && !loading">
          <b-col class="p-0">
            <b-list-group>
              <b-list-group-item>No Contacts for this Customer</b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="loading"
           class="d-flex justify-content-center pt-4">
        <b-row>
          <b-spinner></b-spinner>
        </b-row>
      </div>
      <b-modal size="xl"
               id="contactAddModal"
               centered
               title="Add Contact"
               :busy="true">
        <b-row>
          <span>Full Name</span>
        </b-row>
        <b-row>
          <b-input v-model="newContact.fullname"></b-input>
        </b-row>
        <b-row>
          <span>Email Address</span>
        </b-row>
        <b-row>
          <b-input v-model="newContact.emailAddress"></b-input>
        </b-row>
        <template #modal-footer="{}">
          <b-button size="sm"
                    variant="success"
                    @click="saveNewContact"
                    :disabled="loading"> Save </b-button>
          <b-button size="sm"
                    variant="danger"
                    @click="cancel"> Cancel </b-button>
        </template>
      </b-modal>
      <div v-if="selectedContact != null">
        <b-modal size="xl"
                 id="contactRouteListView"
                 centered
                 title="View PickUp List"
                 :busy="true">
                 <b-row  v-if="contactroutes.length > 0 && !loading" >
                  <b-col class="p-0">
                    <b-list-group v-for="contactroute in contactroutes"
                                  :key="'Customer' + contactroute">
                      <b-list-group-item v-if="contactroute.clientEmail.id == selectedContact.id">
                         PickUp Location: {{ contactroute.pickUpLocation.name }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
        </b-row>
        <template #modal-footer="{}">
          <b-button size="sm"
                    variant="danger"
                    @click="cancel"> Cancel </b-button>
        </template>
        </b-modal>
        <b-modal size="xl"
                 id="contactUpdateModal"
                 centered
                 title="Update Contact"
                 :busy="true">
          <b-row>
            <span>Full Name</span>
          </b-row>
          <b-row>
            <b-input v-model="selectedContact.fullname"></b-input>
          </b-row>
          <b-row>
            <span>Email Address</span>
          </b-row>
          <b-row>
            <b-input v-model="selectedContact.emailAddress" ></b-input>
          </b-row>
          <b-row>
          <span>PickUp Location</span>
        </b-row>
        <b-row>
          <b-form-select v-model="contactRoute.pickUpLocation"  >
            <b-form-select-option v-for="(item, index) in locations" :key="index" :value="item" > 
              {{item.name}}
            </b-form-select-option>
          </b-form-select>
        </b-row>
          <template #modal-footer="{}">
            <b-button size="sm"
                      variant="success"
                      @click="updateContact"
                      :disabled="loading"> Save </b-button>
            <b-button size="sm"
                      variant="danger"
                      @click="cancel"> Cancel </b-button>
          </template>
        </b-modal>
        <b-modal size="xl"
                 id="contactDeleteModal"
                 centered
                 title="Delete Contact"
                 :busy="true">
          <b-row>
            <span>Are you sure you want to delete this contact: {{ selectedContact.emailAddress }}</span>
          </b-row>
          <template #modal-footer="{}">
            <b-button size="sm"
                      variant="success"
                      @click="deleteContact"
                      :disabled="loading"> Confirm </b-button>
            <b-button size="sm"
                      variant="danger"
                      @click="cancel"> Cancel </b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";
import sortLocations from "@/sortAlgorithm";

export default {
  name: "VendorContactList",
  data: () => ({
    date: "",
    contacts: [],
    contactroutes: [],
    loading: false,
    entityId: null,
    vustomer: null,
    searchInput: "",
    filteredList: [],
    locations:[],
    selectedContact: null,
    selectedClustomer: null,
    contactRoute:{
      clientEmailid:null,
      pickUpLocation: null,
      CustomerId: null,
    },
    newContact: {
      id: null,
      emailAddress: null,
      fullname: null
    },
  
  }),
  methods: {
    ...mapMutations("breadcrumbService", ["setBreadcrumb"]),
    editContact(contact) {
      this.selectedContact = { ...contact };
      this.$bvModal.show("contactUpdateModal");
    },
    confirmDeleteContact(contact) {
      this.selectedContact = contact;
      this.$bvModal.show("contactDeleteModal");
    },
    viewpickUplocationlist(contact){
      this.selectedContact = contact;
      this.$bvModal.show("contactRouteListView")
    },
    deleteContact() {
      let index = this.contacts.findIndex(item => item.id === this.selectedContact.id);

      if (index !== -1) {
        this.loading = true;
        api.DeleteCustomerEmailAddress({ entityId: this.entityId, contact: this.selectedContact }, () => {
          this.contacts.splice(index, 1);
          this.makeToast("Contact Delete", "Contact Successfully Deleted: " + this.selectedContact.emailAddress);
          this.$bvModal.hide("contactDeleteModal");
          this.selectedContact = null;
          this.loading = false;
        },
          (e) => {
            console.error(e)
            this.makeToast("Contact Delete", "Failed to delete contact", "warning")
            this.loading = false;
          })
      }
    },
    addContact() {
      this.$bvModal.show("contactAddModal");
    },
    saveNewContact() {
      let contact = this.contacts.find(item => item.emailAddress.trim().toLowerCase() === this.newContact.emailAddress.trim().toLowerCase());

      if (!contact) {
        this.loading = true;

        api.AddClientEmailAddress({ entityId: this.entityId, documentType: 'ClientBookinForm', person: { email: this.newContact.emailAddress, name: this.newContact.fullname } }, (data) => {
          this.contacts.unshift({ id: data, emailAddress: this.newContact.emailAddress, fullname: this.newContact.fullname });
          this.$bvModal.hide("contactAddModal");
          this.makeToast("Contact Add", "Contact Successfully Added: " + this.newContact.emailAddress); this.loading = false;
          this.selectedContact = null;
          this.newContact.emailAddress = null;
          this.newContact.fullname = null;

          this.loading = false;
        },
          (e) => {
            console.error(e)
            this.makeToast("Contact Add", "Failed to Add contact", "warning")
            this.loading = false;
        })
        
      } else {
        this.makeToast("Contact Add", "Failed to Add already existing contact", "warning")
      }
    },
    updateContact() {
      let index = this.contacts.findIndex(item => item.id === this.selectedContact.id);

      if (index !== -1) {
        let contact = this.contacts.find(item => item.id === this.selectedContact.id);

        let duplicateContact = this.contacts.find(item => item.emailAddress.trim().toLowerCase() === this.selectedContact.emailAddress.trim().toLowerCase());

        if ((duplicateContact && duplicateContact.id === contact.id && duplicateContact.emailAddress.trim().toLowerCase() === contact.emailAddress.trim().toLowerCase()) || !duplicateContact) {
          this.loading = true;
          api.UpdateCustomerEmailAddress({ entityId: this.entityId, contact: this.selectedContact }, () => {
            this.contacts[index] = { ...this.selectedContact };
            this.loading = false;
            this.loading = false;
          },
           (e) => {
              console.error(e)
              this.loading = false;
            })
        } else {
          this.makeToast("Contact Update", "Failed to update. Email Address already exists", "warning")
        }
      }
        //add ContactRoute put api here.
        api.createContactRoute( {
          id: 0, customerId: this.entityId, 
          clientEmail: this.selectedContact, 
          pickUpLocation: this.contactRoute.pickUpLocation
        },
          ()=>
          {
            this.$bvModal.hide("contactUpdateModal");
            this.makeToast("Contact Update", "Contact Successfully Updated: " + this.selectedContact.emailAddress);
            this.loading = false;
          },
          (error)=>{
            console.error(error);
            this.isSaving = false;
            this.makeToast("Contact Update", "Failed to update contact", "warning")
            this.loading = false;
          } 
        )
    },
    cancel() {
      this.selectedContact = null;
      this.newContact.id = null;
      this.newContact.emailAddress = null;
      this.newContact.fullname = null;

      this.$bvModal.hide("contactUpdateModal");
      this.$bvModal.hide("contactDeleteModal");
      this.$bvModal.hide("contactAddModal");
    },
    getContacts() {
      this.loading = true;
      api.getContactsByCustomerByID(this.entityId, (data) => {
        this.contacts = data
        this.loading = false;
      },
        (e) => {
          console.error(e)
          this.makeToast("Contact List", "Failed to load Contact list for vustomer", "warning")
          this.loading = false;
        })
    }
  },
  created() {
    this.date = this.$route.params["date"]
    this.entityId = this.$route.params["Customer"] 
    this.getContacts();
    api.getCustomer(this.entityId, (data) => {

      this.vustomer = data
      this.setBreadcrumb([
        {
          text: 'Contacts',
        },
        {
          text: 'Customer',
          to: { name: 'customerList' },
        },
        {
          text: this.vustomer.name,
          active: true
        }

      ])
    },
      (e) => {
        console.error(e)
        this.makeToast("Customer List", "Failed to get vustomer information", "warning")
      })

      const s = (data) => 
      {
        this.locations = data;
        sortLocations(this.locations); 
      };
      api.getLocationList(s);

      const t = (data) =>{
        this.contactroutes = data;
      }
      api.getAllContactRoutes(t);
      
  }
}
</script>

<style scoped>
.page {
  background-color: whitesmoke;
  width: 1200px;
  padding: 125px 150px;
  margin: 0 auto;
}
</style>