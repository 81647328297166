<template>
  <div class="document-generation" id="LoadConfirmationList">
    <div class="page">
      <b-row><H1> Contacts </H1></b-row>
      <b-row><H2> Vendor List</H2></b-row>
      <div v-if="vendorList.length != 0 && !loading">
        <b-row>
          <b-input-group class="mb-3 w-100" prepend="Vendor">
            <b-form-input type="text"
                          v-model="searchInput"></b-form-input>
            <b-input-group-append>
              <b-button
                  @click="search">Search
                <b-icon-search/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-row>
        <b-row>
          <b-col class="p-0">
            <b-list-group v-for="vendor in filteredList" :key="'Vendor'+vendor.id" >
              <b-list-group-item
                  :to="{name:'vendorContactList',params:{Vendor:vendor.id}}">

<!--                <b-icon :icon="statusIcon(getDocumentState(vendor.id))"-->
<!--                        :variant="statusIconVariant(getDocumentState(vendor.id))">-->
<!--                </b-icon>-->
                {{ vendor.name }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </div>
      <b-row v-if="vendorList.length == 0 && !loading">
        <b-col class="p-0">
          <b-list-group>
            <b-list-group-item>No Vendors found.</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <div v-if="loading" class="d-flex justify-content-center pt-4">
        <b-row>
          <b-spinner></b-spinner>
        </b-row>
      </div>
    </div>

  </div>
</template>

<script>
import api from "@/api";
import {mapMutations} from "vuex";

export default {
  name: "VendorList",
  data: () => ({
    date: "",
    vendorList: [],
    loading: false,
    searchInput: "",
    filteredList: [],
    documentMeta: []
  }),
  beforeMount(){
    // this.GetVendorList();
  },
  computed: {},
  methods: {
    ...mapMutations("breadcrumbService", ["setBreadcrumb"]),
    GetVendorList(ctx) {
      if (!this.date || ctx.selectedYMD.toString() === this.$route.params["startDate"]) {
        return
      }
      let dateString = ctx.selectedYMD.toString()
      this.$router.push({path: `/LoadConfirmation/${dateString}`}).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })

    },
    search() {

      this.filteredList = this.vendorList.filter(x => x.name.toLowerCase().includes(this.searchInput.toLowerCase()))
    },
    statusIcon(status) {
      if (status === "Accepted") {
        return "patch-check-fill"
      }
      if (status === "Rejected") {
        return "patch-exclamation-fill"
      }
      return "patch-question-fill"
    },
    statusIconVariant(status) {
      if (status === "Accepted") {
        return "success"
      }
      if (status === "Rejected") {
        return "warning"
      }
      return "info"
    },

    getDocumentState(entityId) {

      var doc = this.documentMeta.filter(x => x.entityId == entityId)[0]
      if (doc) {
        switch (doc.status) {
          case 0:
            return "Pending"
          case 1:
            return "Accepted"
          case 2:
            return "Rejected"
        }
      }
      return ""
    }
  },
  created() {
    this.loading = true;
    api.getVendorList((data) => {
      this.vendorList = data
      this.filteredList = this.vendorList
      // api.getLcDocumentMetaByDate(this.date, (doc) => {
      //   this.documentMeta = doc
      //   this.loading = false;
      // }, err => {
      //   console.error(err)
      //   this.makeToast("Vendor List", "Failed to load Document Meta list for date", "warning")
      //   this.loading = false;
      // })
      this.loading = false;
    }, (e) => {
      console.error(e)
      this.makeToast("Vendor List", "Failed to load Vendor list.", "warning")
    })

    if (this.$route.params["date"]) {
      this.date = this.$route.params["date"]
      this.setBreadcrumb([
        {
          text: 'Documentations',

        },
        {
          text: 'Load Confirmation',
          to: {name: 'loadConfirmation-list'},
        },
        {
          text: 'Start Date : ' + this.date,
          active: true
        }

      ])
      
    } else {
      this.setBreadcrumb([
        {
          text: 'Contacts',

        },
        {
          text: 'Vendors',
          active: true,
        },

      ])
    }

  }
}
</script>

<style lang="scss" scoped>
.page {
  background-color: whitesmoke;
  width: 1200px;
  padding: 125px 150px;
  margin: 0 auto;
}



</style>