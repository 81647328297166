<template>
  <div class="document-generation"
       id="PurchaseOrderList">
    <div class="page">
      <b-row>
        <H1> Customer Booking Form </H1>
      </b-row>
      <b-row>
        <H2> OrderList</H2>
      </b-row>
      <b-row v-if="bulkOrders.length != 0">
        <b-col class="p-0">
          <b-list-group v-for="order in bulkOrders"
                        :key="'Customer' + order">
            <b-list-group-item :class="getDocumentState(order.id)"
                               :to="{ name: 'clientBookingForm', params: { Customer: entityId, date: date, order: order.id } }">
              <b-icon :id="'Customer' + order"
                      :icon="statusIcon(order.status)"
                      :variant="statusIconVariant(order.status)">
              </b-icon>
              <b-tooltip :target="'Customer' + order">
                {{ getDocumentState(order.id) }}
              </b-tooltip>
              {{ order.id }} - Pickup Location : "{{ order.pickupLocation.name }}" - Dropoff Location: "{{ order.dropoffLocation.name }}" </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row v-if="bulkOrders.length == 0 && !loading">
        <b-col class="p-0">
          <b-list-group>
            <b-list-group-item>No orders for this customer at this date</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <div v-if="loading"
           class="d-flex justify-content-center pt-4">
        <b-row>
          <b-spinner></b-spinner>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";

export default {
  name: "CustomerList",
  data: () => ({
    date: "",
    bulkOrders: [],
    loading: false,
    entityId: null,
    customer: null,
    searchInput: "",
    filteredList: [],
    documentMeta: []
  }),
  methods: {
    ...mapMutations("breadcrumbService", ["setBreadcrumb"]),
    GetVendorList(ctx) {
      if (!this.date || ctx.selectedYMD.toString() === this.$route.params["date"]) {
        return
      }
      let dateString = ctx.selectedYMD.toString()
      console.log(dateString)
      this.$router.push({ path: '/ClientBooking/' + dateString })
    },
    statusIcon(status) {
      if (status === "Accepted") {
        return "patch-check-fill"
      }
      if (status === "Rejected") {
        return "patch-exclamation-fill"
      }
      return "patch-question-fill"
    },
    statusIconVariant(status) {
      if (status === "Accepted") {
        return "success"
      }
      if (status === "Rejected") {
        return "warning"
      }
      return "info"
    },

    getDocumentState(bulkOrderId) {
      var doc = this.documentMeta.filter(x => x.bulkOrderId == bulkOrderId)[0]

      if (doc) {
        switch (doc.status) {
          case 0:
            return "Pending"
          case 1:
            return "Accepted"
          case 2:
            return "Rejected"
        }
      }
      return ""
    }
  },
  created() {
    this.entityId = this.$route.params["Customer"]
    this.startDate = this.$route.params["StartDate"];
    this.endDate = this.$route.params["EndDate"];

    if (this.startDate && this.endDate) {
      this.loading = true;
      api.getCustomer(this.entityId, (data) => {
        const dateNavigation = this.startDate === this.endDate ? this.startDate : this.startDate + " to " + this.endDate;

        this.customer = data
        this.setBreadcrumb([
          {
            text: 'Documentations',

          },
          {
            text: 'Customer Booking',
            to: { name: 'clientBookingForm-list' },
          },
          {
            text: 'Date : ' + dateNavigation,
            to: { name: 'clientBookingForm-list-date', params: { StartDate: this.startDate, EndDate: this.endDate } },
          },
          {
            text: this.customer.name,
            active: true
          }

        ])
        this.loading = false;
      },
        (e) => {
          console.error(e)
          this.makeToast("Customer List", "Failed to load Order list for customer at this date", "warning")
          this.loading = false;
        })

      api.getCustomerBulkOrders(this.entityId, this.startDate, this.endDate, (data) => {

        this.bulkOrders = data
        //get BulkOrders Meta

        api.getCustomerBulkOrdersMeta(this.entityId, this.startDate, this.endDate, (bulkOrders) => {
          this.documentMeta = bulkOrders
          //get BulkOrders Meta
          console.log(bulkOrders)
        },
          (e) => {
            console.error(e)
            this.makeToast("Customer List", "Failed to load Customer list for date", "warning")
          })
      },
        (e) => {
          console.error(e)
          this.makeToast("Customer List", "Failed to load Customer list for date", "warning")
        })


    }
  }
}
</script>

<style scoped>
.page {
  background-color: whitesmoke;
  width: 1200px;
  padding: 125px 150px;
  margin: 0 auto;
}
</style>