<template>
  <div class="home">


    <b-row class="m-0 dummy">
      <!-- <b-col cols="12" class="p-0"> -->

      <!-- <sidebar /> -->
      <b-col class="p-0">
        <navigator/>
        <b-breadcrumb :items="breadcrumbs" style="margin: 0"></b-breadcrumb>
        <b-row class="m-0" id="full-space">
          <b-col class="p-0 m-0 mt-3" cols="12">
            <router-view :key="$route.fullPath"/>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </div>
</template>

<script>
// import sidebar from "@/components/sidebar";
import navigator from "@/components/navigator";
import breadcrumbService from '@/store/breadcrumbService'
import {mapState} from 'vuex'

export default {
  name: "OuterView",
  components: {
    navigator,
    // sidebar
  },
  beforeCreate() {

    if (!this.$store.hasModule('breadcrumbService')) {
      this.$store.registerModule('breadcrumbService', breadcrumbService);
    }
  },
  computed: {
    ...mapState('breadcrumbService', ['breadcrumbs'])
  },
  mounted() {
    this.$store.commit('setApiUrl', this.$root.urlConfig.api_url)
  },
}
</script>

<style >
.dummy{
  min-height: 100vh;
}
#full-space {
  width: 100%;
  height: calc(100vh - 140px);
  overflow-y: scroll;
}
</style>