<template>
    <div>
        <b-row>
            <div style="width: 100%;">
                <b-row>
                    <b-col>
                        <h4>Assign Location to Template</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4" class="text-left">
                        <label>Location</label>
                        <b-form-select v-model="locationTemplate.location" :disabled="isSaving" >
                            <b-form-select-option v-for="(item, index) in locations" :key="index" :value="item" > 
                                {{item.name}}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col cols="4" class="text-left">
                        <label>Template</label>
                        <b-form-select v-model="locationTemplate.documentTemplate"  :disabled="isSaving" >
                            <b-form-select-option v-for="(items, index) in templates" :key="index" :value="items" > 
                                {{items.name}}
                            </b-form-select-option>
                        </b-form-select>    
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-right">
                        <b-button variant="orange" size="sm"  squared @click="submitLocationTemplate" :disabled="isSaving">{{isSaving ? 'Saved...': "Save" }}</b-button>
                    </b-col>
                </b-row>
            </div>
        </b-row>
        <div v-if="locationTemplate.documentTemplate">
            <b-row>
                <iframe  :src="'http://lcsgdocm.cware.co.za/'+ selectedTemplatePath"
                    frameborder="0"
                    scrolling="auto"
                    style="width: 100%; height: 94vh;"
                    >
                </iframe>
            </b-row>            
        </div>
    </div>
</template>



<script>
    import api from "@/api";
    import sortLocations from "@/sortAlgorithm";
    export default{
        name: "CreateLocationTemplate",
        data: () => ({
            displayLocationTemplateContent: false,
            displayedLocationTemplateName: "",
            documentTemplatePath: "",
            locationTemplate: {
                location: null,
                documentTemplate: null
            },
            locations:[],
            templates:[],
            templateUrl: "",
            documentTemplateView: null,

            isSaving: false
        }),
        computed:{
            
            selectedTemplatePath(){
                return this.locationTemplate.documentTemplate.path.split("/").pop(); 
            },
        },
        created(){


            const s = (data) => {
                this.locations = data;
                sortLocations(this.locations); 
            };

            const e = (msg) => {
                console.log(msg);
            };
            api.getLocationList(s, e);
            
            const d = (data) => {
                this.templates = data;
                sortLocations(this.templates)  
            };

            const f = (msg) => {
                console.log(msg);
            };
            api.getDocumentTemplateList(d,f);
        },
        methods:
        {
            submitLocationTemplate(){
                
                api.CreateLocationTemplate(this.locationTemplate,
                    ()=>
                    {
                        this.isSaving = true;
                        this.$emit("onLocationTemplateSaved",this.locationTemplate);
                    },
                    (error)=>{
                        console.error(error);
                        this.isSaving = false;
                    }            
                );
            },
            close(){

                this.$emit('toggleAddLocationTemplate');
            },

        }
    };
    

</script>

<style scoped>
*,
::before
::after{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}

.vue-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(0,0,0,0.4);
    z-index:1;
}

.modal-footer {
  display: none !important;
}

.vue-modal-inner{
    max-width: 500px;
    margin: 2rem auto;
}

.vue-modal-content{
    position: relative;
    background-color: rgb(109, 109, 107);
    border: 1px solid rgba(0,0,0,0.4);
    background-clip: padding-box;
    border-radius: 0.3rem;
    padding: 1rem;

}

.fade-enter-active,
.fade-leave-active{
    transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to{
    opacity: 0;
}

h4{
    text-align: left;
}
h5{
    text-align: left;
}
</style>