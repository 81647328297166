<template>
  <div class="document-generation" id="LoadConfirmationList">
    <div class="page">
      <b-row><H1> Contacts </H1></b-row>
      <b-row><H2> Customer List</H2></b-row>
      <div v-if="customerList.length != 0 && !loading">
        <b-row>
          <b-input-group class="mb-3 w-100" prepend="Customer">
            <b-form-input type="text"
                          v-model="searchInput"></b-form-input>
            <b-input-group-append>
              <b-button
                  @click="search">Search
                <b-icon-search/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-row>
        <b-row>
          <b-col class="p-0">
            <b-list-group v-for="customer in filteredList" :key="'Customer'+customer.id" >
              <b-list-group-item
                  :to="{name:'contactsCustomer',params:{Customer:customer.id}}">
                {{ customer.name }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </div>
      <b-row v-if="customerList.length == 0 && !loading">
        <b-col class="p-0">
          <b-list-group>
            <b-list-group-item>No Customers found.</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <div v-if="loading" class="d-flex justify-content-center pt-4">
        <b-row>
          <b-spinner></b-spinner>
        </b-row>
      </div>
    </div>

  </div>
</template>

<script>
import api from "@/api";
import {mapMutations} from "vuex";
import sortClients from "@/sortAlgorithm";

export default {
  name: "ContactsCustomerList",
  data: () => ({
    date: "",
    customerList: [],
    loading: false,
    searchInput: "",
    filteredList: [],
    documentMeta: [],
    selectedCustomer:null
  }),
  computed: {},
  methods: {
    ...mapMutations("breadcrumbService", ["setBreadcrumb"]),
    search() {

      this.filteredList = this.customerList.filter(x => x.name.toLowerCase().includes(this.searchInput.toLowerCase()))
    },
    statusIcon(status) {
      if (status === "Accepted") {
        return "patch-check-fill"
      }
      if (status === "Rejected") {
        return "patch-exclamation-fill"
      }
      return "patch-question-fill"
    },
    statusIconVariant(status) {
      if (status === "Accepted") {
        return "success"
      }
      if (status === "Rejected") {
        return "warning"
      }
      return "info"
    }
  },
  created() {
    this.loading = true;
    api.getCustomerList((data) => {
      this.customerList = data
      this.filteredList = this.customerList
      sortClients(this.filteredList)
      this.loading = false;
    }, (e) => {
      console.error(e)
      this.makeToast("Customer List", "Failed to load Customer list.", "warning")
    })

    if (this.$route.params["date"]) {
      this.date = this.$route.params["date"]
      this.setBreadcrumb([
        {
          text: 'Documentations',

        },
        {
          text: 'Load Confirmation',
          to: {name: 'loadConfirmation-list'},
        },
        {
          text: 'Start Date : ' + this.date,
          active: true
        }

      ])
      
    } else {
      this.setBreadcrumb([
        {
          text: 'Contacts',

        },
        {
          text: 'Customers',
          active: true,
        },

      ])
    }

  }
}
</script>

<style lang="scss" scoped>
.page {
  background-color: whitesmoke;
  width: 1200px;
  padding: 125px 150px;
  margin: 0 auto;
}



</style>