<template>
  <div id="PurchaseOrder"
       class="m-0">
    <div class="row page shadow"
         v-if="lcDocument !== null">
      <div class="row">
        <div class="column"
             style="height: 10px">
          <!--        <img class="header-image" alt="LCS Header" src="http://192.168.48.9:8080/img/Header_Logo.4e6a4098.png">-->
          <img class="header-image"
               alt="LCS Header"
               src="../../assets/Header_Logo.png">
        </div>
        <div class="column header-text-left">
          <p class="mt-0">c/o Stasie & Meyer Street, Heidelberg, Gauteng 1441</p>
          <p class="mt-0">PO Box 1680, Heidelberg, Gauteng 1438</p>
          <p class="mt-0">p: 086 152 7476</p>
          <p class="mt-0">e: supportlcsgroup.co.za</p>
          <p class="mt-0">w: www.lcsgroup.co.za</p>
        </div>
      </div>
      <div class="row">
        <div class="row">
          <div class="column align-bottom">
            <h4>LCS Logistics (Pty) Ltd</h4>
            <h1>Load Confirmation # {{ meta.orderNr }}</h1>
          </div>
          <div class="qr-right">
            <img class="qr-img"
                 :src="'data:image/png;base64,' + lcDocument.qrBase64" />
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="column">
            <h2 class="mb-1">Contact Person</h2>
            <p class="bold mt-0">{{ lcDocument.contactPerson.name }}</p>
            <p class="mt-0">{{ lcDocument.contactPerson.landline }}</p>
            <p class="mt-0">{{ lcDocument.contactPerson.cellPhone }}</p>
            <p class="mt-0 email">{{ lcDocument.contactPerson.email }}</p>
            <p><span class="bold">Company Reg No:</span> {{ lcDocument.lcsDetails.companyRegNo }}</p>
            <p class="mt-0"><span class="bold">VAT number:</span> {{ lcDocument.lcsDetails.vatNo }}</p>
          </div>
          <div class="column text-right">
            <h2 class="">Please Address All Invoices &<br> Original Documents To:</h2>
            <p class="mt-0">{{ lcDocument.lcsDetails.address1 }}</p>
            <p class="mt-0">{{ lcDocument.lcsDetails.address2 }}</p>
            <p class="mt-0">{{ lcDocument.lcsDetails.city }}</p>
            <p class="mt-0">{{ lcDocument.lcsDetails.province }}</p>
            <p class="mt-0">{{ lcDocument.lcsDetails.postalCode }}</p>
            <p class="mt-0"><span class="bold">Tel: </span>{{ lcDocument.lcsDetails.landline }}</p>
          </div>
        </div>
        <div class="mb-1">
          <h2 class="mb-1">Contractor Company Details</h2>
          <p class="mt-0"><span class="bold">Name: </span>{{ lcDocument.vendor.name }}</p>
          <p class="mt-0"><span class="bold">Tel: </span>{{ lcDocument.vendor.tel }}</p>
          <p class="mt-0"><span class="bold">Email: </span><span class="email">{{ lcDocument.vendor.email }}</span></p>
        </div>
        <h3>Load Confirmation Details</h3>
        <table class="transport-table">
          <tr>
            <th class="text-center">Loading Point</th>
            <th class="text-center">Offloading Point</th>
            <th class="text-center">Material</th>
            <th class="text-center">Invoice Tons</th>
            <th class="text-center">Service Date</th>
            <th class="text-center">Rate/ton</th>
          </tr>
          <!--          <tr v-for="item of lcDocument.loadItem" v-bind:key="item.purchaseOrderNumber">-->
          <tr>
            <td>{{ lcDocument.loadItem.pickupLocation }}</td>
            <td>{{ lcDocument.loadItem.dropoffLocation }}</td>
            <td>{{ lcDocument.loadItem.material }}</td>
            <td>{{ lcDocument.loadItem.invoiceTons }}</td>
            <td>{{ lcDocument.loadItem.effectiveDate }}</td>
            <td>{{ lcDocument.loadItem.rate }}</td>
          </tr>
        </table>
        <h2 class="">All Transport Rates Are VAT Exclusive</h2>
        <h2 class="">Conditions of Payment:</h2>
        <ul>
          <li>Payment will be made on the next working day, should the original payment date fall on a weekend or public holiday </li>
          <li>Please submit all LCS Load Confirmations together with Invoices before the cut-off date of the 15th or last day of the month. Invoices older than 6 months will not be considered for payment. Multiple loads may reflect on one invoice, but a LCS loadcon per load is required </li>
          <li> Original Documents required per load for Payment: <ul>
              <li>LCS Load confirmation per load.</li>
              <li>Loading Weighbridge certificate.</li>
              <li>Offloading Weighbridge certificate.</li>
              <li>Delivery Note (TSP) signed by receiver</li>
              <li>Contractor invoice</li>
            </ul>
          </li>
          <li>All documents to be delivered to: <span class="bold">LCS Logistics, 4 Meyer Street, Heidelberg, Gauteng 1441.</span>
          </li>
          <li> Payment terms after Statement:<br>
            <span>Standard Terms Apply</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="row button-List">
      <b-button id="EmailActions"> Email Actions <b-icon icon="triangle-fill" class="triangle-icon"></b-icon></b-button>
      <b-tooltip target="EmailActions">
        <b-button v-b-modal.AddEmailModal class="tooltip-button mb-1" :disabled="isDisableButton">Add Email address and Send</b-button>
        <b-button v-b-modal.CustomEmailModal class="tooltip-button mb-1" :disabled="isDisableButton">Send To Custom Email Address</b-button>
        <b-button @click="SendLCS" class="tooltip-button mb-1" :disabled="isDisableButton">Send To LCS User </b-button>
          <b-button @click="Accept" class="tooltip-button" :disabled="isDisableButton">{{!isDisableButton ? "Accept" : ""}}<b-spinner v-if="isDisableButton"></b-spinner></b-button>
      </b-tooltip> &nbsp; <b-button id="RejectOrder" v-b-modal.RejectionModal :disabled="isDisableButton">Reject</b-button> &nbsp; <b-button id="ExportOrder" @click="Export">Export (PDF)</b-button>
    </div>

    <!--    Add Email Modal-->
    <b-modal id="AddEmailModal"
             title="Custom Email"
             @ok="AddEmail"
             @hide="ClearModals">
      <b-input-group prepend="Full Name">
        <b-form-input v-model="modals.name"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Email Address">
        <b-form-input v-model="modals.customEmail"></b-form-input>
      </b-input-group>
    </b-modal>
    <!--    Custom Email Modal-->
    <b-modal id="CustomEmailModal"
             title="Custom Email"
             @ok="SendCustom"
             @hide="ClearModals">
      <b-input-group prepend="Full Name">
        <b-form-input v-model="modals.name"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Email Address">
        <b-form-input v-model="modals.customEmail"></b-form-input>
      </b-input-group>
    </b-modal>
    <!--    Rejection Modal-->
    <b-modal id="RejectionModal"
             title="Rejection"
             @ok="Reject"
             @hide="ClearModals">
      <H2>Please enter a reason for your rejection</H2>
      <!--      <b-input-group prepend="Reason">-->
      <b-form-input v-model="modals.rejection"></b-form-input>
      <!--      </b-input-group>-->
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";

export default {
  name: "PurchaseOrder",
  data: () => ({
    orderId: "",
    startDate: "",
    endDate: "",
    lcDocument: null,
    validityFrom: null,
    validityTo: null,
    dateIssued: null,
    contactPerson: {},
    requisitioner: {},
    lcsDetails: {},
    meta: {},
    modals: {
      rejection: null,
      customEmail: null
    },
    isDisableButton: false
  }),
  created() {
    this.orderId = this.$route.params["order"]
    this.startDate = this.$route.params["StartDate"]
    this.endDate = this.$route.params["EndDate"]

    // var vendor
    api.getLcMeta(this.orderId, (data) => {

      this.meta = data
    },
      () => this.makeToast("Load Confirmation", "Failed to get Load Confirmation meta", "danger"))


    api.getLc(this.orderId, (data) => {

      this.lcDocument = { ...data }
      console.log("Data: ", this.lcDocument)
      // this.lcDocument.monthYear = date.toLocaleDateString('en-US', {
      //   day: undefined,
      //   month: "short",
      //   year: "numeric"
      // })

      const dateNavigation = this.startDate === this.endDate ? this.startDate: this.startDate + " to " + this.endDate;

      this.setBreadcrumb([
        {
          text: 'Documentations',

        },
        {
          text: 'Load Confirmation',
          to: { name: 'loadConfirmation-list' },
        }, {
          text: 'Date : ' + dateNavigation,
          to: { name: 'loadConfirmation-list-date', param: { StartDate: this.startDate, EndDate: this.endDate } }
        },
        {
          text: 'Vendor : ' + this.lcDocument.vendor.name,
          to: { name: 'loadConfirmationOrders', param: { StartDate: this.startDate, EndDate: this.endDate, Vendor: data.id } },
        },
        {
          text: 'Order : ' + this.orderId,
          active: true
        }

      ])
      console.log(this.lcDocument.monthYear)
    },
      () => this.makeToast("Load Confirmation", "Failed to get Load Confirmation", "danger"))
  },
  methods: {
    ...mapMutations("breadcrumbService", ["setBreadcrumb"]),
    Accept() {
      this.isDisableButton = true;
      api.SendLcToVendor(this.meta,
        () => {
          this.makeToast("Purchase Order", "Document sent to " + this.lcDocument.vendor.name)
          this.isDisableButton = false;
        }, () => {
          this.makeToast("Purchase Order", "Failed to send Document to " + this.lcDocument.vendor.name)
          this.isDisableButton = false;
        },
      )
    },
    AddEmail() {
      var data = {
        "documentType": "LoadConfirmationDocument",
        "entityId": this.meta.entityId,
        "person": {
          "name": this.modals.name,
          "email": this.modals.customEmail
        }

      }
      api.AddVendorEmailAddress(data,
        () => {
          this.makeToast("Load Confirmation", "Document sent to " + this.lcDocument.vendor.name)
          this.Accept();
        }, () => {
          this.makeToast("Load Confirmation", "Failed to send Document to " + this.lcDocument.vendor.name)
        },
      )
    },
    SendCustom() {
      var data = {
        "document": this.meta,
        "person": {
          "name": this.modals.name,
          "landline": "",
          "cellPhone": "",
          "email": this.modals.customEmail
        }
      }
      api.SendLcToCustom(data,
        () => {
          this.makeToast("Load Confirmation", "Document sent to " + this.lcDocument.vendor.name)

        }, () => {
          this.makeToast("Load Confirmation", "Failed to send Document to " + this.lcDocument.vendor.name)
        },
      )
    },
    SendLCS() {
      api.SendLcToLCS(this.meta,
        () => {
          this.makeToast("Load Confirmation", "Document sent to " + this.lcDocument.vendor.name)
        }, () => {
          this.makeToast("Load Confirmation", "Failed to send Document to " + this.lcDocument.vendor.name)
        },
      )
    },
    Reject() {
      this.meta.reason = this.modals.rejection
      api.RejectLc(this.meta,
        () => {
          this.makeToast("Load Confirmation", "Document has been reported")
        }, () => {
          this.makeToast("Load Confirmation", "Failed report Document")
        },
      )
    },
    Export() {

      api.getLcPDF(this.meta.id,
        (data) => {
          // Let's create a link in the document that we'll
          // programmatically 'click'.
          const link = document.createElement('a');

          // Tell the browser to associate the response data to
          // the URL of the link we created above.
          link.href = window.URL.createObjectURL(
            new Blob([data])
          );

          // Tell the browser to download, not render, the file.
          link.setAttribute('download', 'Load Confirmation - ' + this.lcDocument.vendor.code + '.pdf');

          // Place the link in the DOM.
          document.body.appendChild(link);

          // Make the magic happen!
          link.click();
        }, () => {
          this.makeToast("Load Confirmation", "Failed to export Document to " + this.lcDocument.vendor.name)
        },
      )
    },
    ClearModals() {
      this.modals = {
        rejection: null,
        customEmail: null
      }
    }
  },
  computed: {}
}
</script>

<style scoped>

#PurchaseOrder {
  text-align: left;

}

* {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
}

h1 {
  font-size: 24px;
}

h2 {
  color: rgb(47, 143, 188);
  font-size: 17px;
}

h3 {
  font-size: 13px;
  margin-bottom: 0rem;
}

h4 {
  font-size: 16px;
  color: rgb(158, 158, 158);
  font-weight: 300;
  margin-bottom: 0rem;
}

p {
  margin-bottom: 0rem;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
}

td,
th {
  border: 1px solid black;
  text-align: left;
  padding: 8px;
}

th {
  background-color: rgb(217, 217, 217);
}


li {
  margin-top: .75rem;
}

.red-text {
  color: red;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: 700;
}

.email {
  color: rgb(76, 76, 204);
  text-decoration: underline;
}

.underline {
  text-decoration: underline;
}

.signature-paragraph {
  border-top: 2px solid black;
  margin-top: 5rem;
  margin-right: 3rem;
  font-weight: 700;
}

table.transport-table tr th {
  background-color: rgb(32, 32, 112);
  color: white;
}

.column {
  float: left;
  width: 50%;
}

.row {
  display: block !important;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.mt-0 {
  margin-top: 0rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.mb-1 {
  margin-bottom: 0.5rem
}

.page {
  background-color: whitesmoke;
  width: 1200px;
  padding: 125px 150px;
  margin: 0 auto !important;
}

.img-columns {
  text-align: center;
}

.header-text-left {
  color: #A4B0B6;
  font-size: 12px;
  text-align: right;
}

.header-image {
  width: 294px;
  position: relative;
  left: -150px;
  top: -125px;
}

.button-List {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.align-bottom {
  margin-top: 144px;
}

.qr-img {
  width: 200px;
  height: 200px;
}

.qr-right {
  float: right;
  width: 200px;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 54px 55px, rgba(0, 0, 0, 0.24) 0px -12px 30px, rgba(0, 0, 0, 0.24) 0px 4px 6px, rgba(0, 0, 0, 0.34) 0px 12px 13px, rgba(0, 0, 0, 0.20) 0px -3px 5px;
}

.tooltip-inner {
  padding: 0px !important;
}
</style>