<template>
  <div class="document-generation"
       id="PurchaseOrderList">
    <div class="page">
      <b-row>
        <H1> Load Confirmation </H1>
      </b-row>
      <b-row>
        <H2> OrderList</H2>
      </b-row>
      <b-row>
        <b-input-group class="mb-3 w-100"
                        prepend="Orders">
          <b-form-input type="text"
                        v-model="searchInput" @input="search"></b-form-input>
          <b-input-group-append>
            <b-button @click="search">Search <b-icon-search />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-row>
      <b-row v-if="filteredList.length != 0 && !loading">
        <b-col class="p-0">
          <b-list-group v-for="order in filteredList"
                        :key="'Customer' + order">
            <b-list-group-item :class="getDocumentState(order.id)"
                               :to="{ name: 'loadConfirmation', params: { Vendor: entityId, date: date, order: order.orderNr } }">
              <b-icon :id="'Customer' + order"
                      :icon="statusIcon(order.status)"
                      :variant="statusIconVariant(order.status)">
              </b-icon>
              <b-tooltip :target="'Customer' + order">
                {{ getDocumentState(order.id) }}
              </b-tooltip> Order Number :{{ order.orderNr }} </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row v-if="filteredList.length == 0 && !loading">
        <b-col class="p-0">
          <b-list-group>
            <b-list-group-item>No orders for this Vendor at this date</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <div v-if="loading"
           class="d-flex justify-content-center pt-4">
        <b-row>
          <b-spinner></b-spinner>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";

export default {
  name: "LoadConfirmationOrderList",
  data: () => ({
    date: "",
    bulkOrders: [],
    loading: false,
    entityId: null,
    vendor: null,
    searchInput: "",
    filteredList: [],
    documentMeta: []
  }),
  methods: {
    ...mapMutations("breadcrumbService", ["setBreadcrumb"]),
    search() {
      this.filteredList = this.bulkOrders.filter(x => x.orderNr.toLowerCase().includes(this.searchInput.toLowerCase()));
      if (this.filteredList.length == 0) {
        this.filteredList = {...this.bulkOrders};
      }
    },
    GetVendorList(ctx) {
      if (!this.startDate || ctx.selectedYMD.toString() === this.$route.params["StartDate"]) {
        return
      }
      let dateString = ctx.selectedYMD.toString()
      console.log(dateString)
      this.$router.push({ path: '/LoadConfirmation/' + dateString })
    },
    statusIcon(status) {
      if (status === "Accepted") {
        return "patch-check-fill"
      }
      if (status === "Rejected") {
        return "patch-exclamation-fill"
      }
      return "patch-question-fill"
    },
    statusIconVariant(status) {
      if (status === "Accepted") {
        return "success"
      }
      if (status === "Rejected") {
        return "warning"
      }
      return "info"
    },

    getDocumentState(bulkOrderId) {

      var doc = this.documentMeta.filter(x => x.bulkOrderId == bulkOrderId)[0]

      switch (doc.status) {
        case 0:
          return "Pending"
        case 1:
          return "Accepted"
        case 2:
          return "Rejected"
      }
      return ""
    }
  },
  created() {
    this.startDate = this.$route.params["StartDate"]
    this.endDate = this.$route.params["EndDate"]
    this.entityId = this.$route.params["Vendor"]
    this.loading = true;
    api.getVendorByID(this.entityId, (data) => {
      const dateNavigation = this.startDate === this.endDate ? this.startDate : this.startDate + " to " + this.endDate;

      this.vendor = data
      this.setBreadcrumb([
        {
          text: 'Documentations',

        },
        {
          text: 'Load Confirmation',
          to: { name: 'loadConfirmation-list' },
        },
        {
          text: 'Date : ' + dateNavigation,
          to: { name: 'loadConfirmation-list-date', params: { StartDate: this.startDate, EndDate: this.endDate } },
        },
        {
          text: this.vendor.name,
          active: true
        }

      ])
    },
      (e) => {
        console.error(e)
        this.makeToast("Vendor List", "Failed to load Order list for vendor at this date", "warning")
      })

    this.loading = true;
    api.getVendorLcBulkOrders(this.entityId, this.startDate, this.endDate, (data) => {

      this.bulkOrders = data
      this.filteredList = {...this.bulkOrders};
      //get BulkOrders Meta
      console.log("Documents: ", data)
      this.loading = false;
      api.getVendorLcBulkOrdersMeta(this.entityId, this.startDate, this.endDate, (bulkOrders) => {
        this.documentMeta = bulkOrders
        //get BulkOrders Meta
        console.log("Meta: ", bulkOrders)
      },
        (e) => {
          console.error(e)
          this.makeToast("Customer List", "Failed to load Customer list for date", "warning")
        })
    },
      (e) => {
        console.error(e)
        this.makeToast("Customer List", "Failed to load Customer list for date", "warning")
        this.loading = false;
      })


  }
}
</script>

<style scoped>
.page {
  background-color: whitesmoke;
  width: 1200px;
  padding: 125px 150px;
  margin: 0 auto;
}</style>