<template>
    <div>
        
    <b-navbar class="pt-0 pb-0" type="dark" sticky>
      <!-- <b-navbar-brand>
        <h3 class="bold" style="color: #222222">LCS Gateway</h3>
      </b-navbar-brand> -->


      <b-navbar-nav class="ml-auto navbar-login-dropdown">

<!--        <b-dropdown-item class="sign-out"  v-b-toggle="'person_collapse'">-->
<!--          <b-icon-person ></b-icon-person>-->
<!--          </b-dropdown-item>-->

<!--        <b-collapse id="person_collapse" :v-model="user_view" left style="position: absolute;right:25px;border: black solid 1px;top: 55px;">-->
<!--          <b-card title="User">-->
<!--            <p>{{userdata.name}}</p>-->
<!--            <p>{{userdata.surname}}</p>-->
<!--            <p>{{userdata.email}}</p>-->
<!--            <p>{{userdata.contactNumber}}</p>-->
<!--            <p>{{userdata.AssociatedEntity.Name}}</p>-->
<!--            <p>{{userdata.role.Name}}</p>-->
<!--          </b-card>-->
<!--        </b-collapse>-->

<!--        <b-dropdown-item class="sign-out" @click="showModal()">-->
<!--            <b-icon-power></b-icon-power>-->
<!--           -->
<!--          </b-dropdown-item>-->
      </b-navbar-nav>
    </b-navbar>

    </div>
</template>
<style lang="scss">
.primary{
background-color: #ffcb14;
color: #222222;
}
.primary:hover{
    color: #fff;
}
b-card{
  z-index: 100;
}

</style>
<script>
export default {
  name:"navigatoComponent",
  data: () => ({
    username: null,
    userdata: null,
    user_view:false
  }),
  created(){
    this.userdata = JSON.parse(localStorage.getItem("user_data"))
    this.username = localStorage.getItem('user')

  },
  methods:{

  }
}
</script>