<template>
  <div class="document-generation" id="PurchaseOrderList">
    <div class="page">
      <div>
        <b-row>
          <H1>Emails</H1>
        </b-row>
        <b-row>
          <date-range-picker @onDateSelected="onDateSelected" />
        </b-row>
        <div v-if="startDate && endDate">
          <b-row>
            <b-col cols="6" class="text-left p-0">
              <H4>Status Types</H4>
            </b-col>
          </b-row>
          <b-row v-if="statusTypes.length != 0" class="mb-3">
            <b-form-select v-model="selectedStatusType" @change="getFailedEmailList"
              :options="statusTypes"></b-form-select>
          </b-row>
          <b-row v-if="filteredEmails.length > 0">
            <b-input-group class="mb-3 w-100">
              <b-form-input type="text" placeholder="Email, Subject, Body" v-model="searchInput" @input="search"></b-form-input>
              <b-input-group-append>
                <b-button @click="search">Search <b-icon-search />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-row>
        </div>
        <b-row v-if="!loading">
          <b-col class="p-0">
            <table v-if="filteredEmails.length > 0">
              <thead>
                <tr>
                  <th>Date Time</th>
                  <th>Email Addresses</th>
                  <th>Subject</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in filteredEmails" :key="row.description">
                  <td>{{ getFormattedDate(row.date) }}</td>
                  <td>{{ row.recipients.map(recipient => recipient.emailAddress).join(', ') }}</td>
                  <td>{{ row.subject }}</td>
                  <td>
                    <b-icon-pencil @click="editEmail(row)" class="mr-3"></b-icon-pencil>
                    <b-icon-trash @click="confirmDeleteEmail(row)"></b-icon-trash>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row v-if="filteredEmails.length == 0 && !loading">
          <b-col class="p-0">
            <b-list-group>
              <b-list-group-item>No Failed Emails Found</b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="loading" class="d-flex justify-content-center pt-4">
        <b-row>
          <b-spinner></b-spinner>
        </b-row>
      </div>
      <div v-if="selectedEmail != null">
        <b-modal size="xl" id="emailUpdateModal" centered title="View Email" :busy="true">
          <b-row>
            <span>Subject</span>
          </b-row>
          <b-row>
            <b-input :value="selectedEmail.subject" disabled=true></b-input>
          </b-row>
          <b-row>
            <span>Date</span>
          </b-row>
          <b-row>
            <b-input disabled=true :value="getFormattedDate(selectedEmail.date)"></b-input>
          </b-row>
          <b-row>
            <span>Status</span>
          </b-row>
          <b-row>
            <b-input disabled=true :value="selectedEmail.status"></b-input>
          </b-row>
          <b-row>
            <span>Recipients</span>
          </b-row>
          <b-row>
            <b-col class="p-0">
              <b-list-group v-for="recipient in selectedEmail.recipients" :key="'Recipient' + recipient">
                <b-list-group-item> {{ recipient.emailAddress }}
                  <span class="float-right">
                    <b-icon-pencil @click="editRecipient(recipient)"></b-icon-pencil>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row>
            <span>Body</span>
          </b-row>
          <b-row>
            <b-col class="p-0">
              <b-form-textarea :value="selectedEmail.body" disabled=true no-auto-shrink no-resize>
              </b-form-textarea>
            </b-col>
          </b-row>
          <div v-if="selectedEmail.attachments.length > 0">
            <b-row>
              <span>Attachments</span>
            </b-row>
            <b-row>
              <b-col class="p-0">
                <b-list-group v-for="attachment in selectedEmail.attachments" :key="'Attachment' + attachment">
                  <b-list-group-item> {{ attachment.fileName }}
                    <span class="float-right">
                      <b-icon-download class="ml-3" @click="downloadAttachment(attachment)"></b-icon-download>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
          </div>
          <template #modal-footer="{}">
            <b-button size="sm" variant="success" @click="resendEmail" :disabled="loading"> Resend Email </b-button>
            <b-button size="sm" variant="danger" @click="cancel"> Cancel </b-button>
          </template>
        </b-modal>

        <div v-if="selectedRecipient">
          <b-modal size="xl" id="recipientUpdateModal" centered title="Update Recipient" :busy="true">
            <b-row>
              <span>Email Address</span>
            </b-row>
            <b-row>
              <b-input v-model="selectedRecipient.emailAddress"></b-input>
            </b-row>
            <template #modal-footer="{}">
              <b-button size="sm" variant="success" @click="updateRecipient" :disabled="loading"> Save </b-button>
              <b-button size="sm" variant="danger" @click="cancel"> Cancel </b-button>
            </template>
          </b-modal>
        </div>

        <b-modal size="xl" id="emailDeleteModal" centered title="Delete Email" :busy="true">
          <b-row>
            <span>Are you sure you want to delete this Email: {{ selectedEmail.subject }}</span>
          </b-row>
          <template #modal-footer="{}">
            <b-button size="sm" variant="success" @click="deleteEmail" :disabled="loading"> Confirm </b-button>
            <b-button size="sm" variant="danger" @click="cancel"> Cancel </b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";
import 'flatpickr/dist/flatpickr.css';
import DateRangePicker from '../../components/DateRangePicker.vue'

export default {
  name: "FailedEmailList",
  data: () => ({
    date: "",
    startDate: "",
    endDate: "",
    emails: [],
    ogFilteredEmails: [],
    filteredEmails: [],
    loading: false,
    entityId: null,
    vendor: null,
    searchInput: "",
    filteredList: [],
    selectedStatusType: null,
    selectedEmail: null,
    selectedRecipient: null,
    ogSelectedRecipient: null,
    newContact: {
      id: null,
      emailAddress: null,
      fullname: null
    },
    statusTypes: [
      {
        text: "Scheduled For Send",
        value: "Send"
      },
      {
        text: "Delivered",
        value: "Delivered"
      },
      {
        text: "Scheduled For Resend",
        value: "Resend"
      },
      {
        text: "Processed",
        value: "Processed"
      },
      {
        text: "Failed",
        value: "Failed"
      },
      {
        text: "All",
        value: "All"
      },
    ],
  }),
  components: {
    DateRangePicker
  },
  methods: {
    ...mapMutations("breadcrumbService", ["setBreadcrumb"]),
    onDateSelected(dateSelected) {
      this.startDate = dateSelected.startDate;
      this.endDate = dateSelected.endDate;

      if (this.selectedStatusType && this.startDate && this.endDate) {
        this.getFailedEmailList();
      }
    },
    search() {
      let search = this.searchInput.toLowerCase();
      this.filteredEmails = this.ogFilteredEmails.filter(x => x.recipients[0].emailAddress.toLowerCase().includes(search) || x.subject.toLowerCase().includes(search) || x.body.toLowerCase().includes(search));
      if (this.filteredEmails.length == 0) {
        this.filteredEmails = [ ...this.ogFilteredEmails ];
      }
    },
    onStatusChange() {
      if (this.selectedStatusType == "Failed") {
        this.filteredEmails = this.emails.filter(item => item.status === "Bounce" || item.status === "Deferred" || item.status === "Dropped");
      } else if (this.selectedStatusType == "All") {
        this.filteredEmails = this.emails;
      }
      else {
        this.filteredEmails = this.emails.filter(item => item.status === this.selectedStatusType);
      }

      this.ogFilteredEmails = [ ...this.filteredEmails ];
    },
    getFormattedDate(date) {
      return date.replace('T', ' ').substring(0, 19);
    },
    editEmail(email) {
      this.selectedEmail = { ...email };
      this.$bvModal.show("emailUpdateModal");
    },
    editRecipient(emailAddress) {
      this.selectedRecipient = emailAddress;
      this.ogSelectedRecipient = { ...emailAddress };

      this.$bvModal.show("recipientUpdateModal");
    },
    confirmDeleteEmail(email) {
      this.selectedEmail = email;
      this.$bvModal.show("emailDeleteModal");
    },
    deleteEmail() {
      let index = this.emails.findIndex(item => item.id === this.selectedEmail.id);

      if (index !== -1) {
        this.loading = true;
        api.DeleteEmail(this.selectedEmail.id, () => {
          this.emails.splice(index, 1);
          this.makeToast("Email Delete", "Email Successfully Deleted: " + this.selectedEmail.subject);
          this.$bvModal.hide("emailDeleteModal");
          this.selectedEmail = null;
          this.loading = false;
        },
          (e) => {
            console.error(e)
            this.makeToast("Email Delete", "Failed to delete email", "warning")
            this.loading = false;
          })
      }
    },
    resendEmail() {
      this.loading = true;
      api.ResendEmail(this.selectedEmail.messageId, (data) => {
        this.selectedEmail = data;
        this.makeToast("Email Resend", "Email Successfully scheduled for resend: " + this.selectedEmail.subject);
        this.loading = false;
      },
        (e) => {
          console.error(e)
          this.makeToast("Email Resend", "Failed to resend email", "warning")
          this.loading = false;
        })
    },
    updateRecipient() {
      if (this.selectedEmail) {
        this.loading = true;
        api.UpdateEmailRecipient(this.selectedEmail, () => {
          this.$bvModal.hide("recipientUpdateModal");
          this.makeToast("Recipient Update", "Recipient Successfully Updated: " + this.selectedRecipient.emailAddress); this.loading = false;
          this.loading = false;
          this.selectedRecipient = null;
          this.ogSelectedRecipient = null;
        },
          (e) => {
            console.error(e)
            this.makeToast("Recipient Update", "Failed to update recipient", "warning")
            this.loading = false;
          })
      }
    },
    cancel() {
      if (this.selectedRecipient) {
        let index = this.selectedEmail.recipients.findIndex(item => item.id === this.selectedRecipient.id);
        this.selectedEmail.recipients[index] = { ...this.ogSelectedRecipient };
        this.selectedRecipient = null;
        this.ogSelectedRecipient = null;
        this.$bvModal.hide("recipientUpdateModal");
      } else if (this.selectedEmail) {
        this.selectedEmail = null;
        this.$bvModal.hide("emailUpdateModal");
        this.$bvModal.hide("emailDeleteModal");
      }
    },
    getFailedEmailList() {
      this.loading = true;
      api.getFailedEmailList(this.startDate, this.endDate, (data) => {
        this.emails = data.reverse();
        this.onStatusChange();
        this.loading = false;
      },
        (e) => {
          console.error(e)
          this.makeToast("Email List", "Failed to load email list", "warning")
          this.loading = false;
        })
    },
    downloadAttachment(attachment) {
      const link = document.createElement('a');
      link.href = `data:${attachment.fileType};base64,${attachment.fileData}`;
      link.download = attachment.fileName;
      link.click();
    },
  }
  ,
  created() {
    this.setBreadcrumb([
      {
        text: "Emails",
      },
      {
        text: "Manage",
        active: true,
      },
    ]);
  }
}
</script>

<style scoped>
.page {
    background-color: whitesmoke;
    width: 96%;
    padding: 5% 2%;
    margin: 0 auto;
}

body,
table {
  margin: 0;
  padding: 0;
}

.table-container {
  width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

th {
  text-align: left;
  padding: 8px 16px;
}

td {
  text-align: left;
  padding: 8px 16px;
}

th {
  background-color: #e9e9e9;
  color: #333;
  font-weight: bold;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

td:nth-child(4) {
  text-align: right;
  min-width: 36px;
  /* Adjust the width as per your icon size and spacing */
}
</style>