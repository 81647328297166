<template>
    <div>
        <b-row>
            <b-col>
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>Search Locations</h4>
                        </b-col>
                        <b-col class="text-right">
                            <b-button variant="orange" size="sm" squared @click="toggleAddLocationTemplate">Assign Template</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4" class ="d-flex">
                            <b-form-input v-model="searchTerm" @keyup="search"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <!--Table-->
        <b-row class="mt-3">
            <b-col>
                <b-card>
                    <b-row>
                        <b-col class="12">
                            <b-table striped hover sort-icon-left
                                :items="tableData.dataSource"
                                :fields="tableData.tableColumns"
                                :busy="tableData.isLoading"
                                :per-page="tableData.reslutsPerPages"
                                id="ManageTemplate"
                                :current-page="tableData.currentPage"
                                >
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <b-spinner style="width: 2rem; height: 2rem;"></b-spinner>
                                    </div>
                                </template>
                                <template #cell(actions)="row">
                                    <b-row align-v="center" align-h="end" >
                                        <b-button variant="background-primary" @click="toggleLocationTemplateUpdate(row.item)" size="sm" class="btn-icon">
                                            <b-icon-pencil></b-icon-pencil>
                                        </b-button>
                                    </b-row>
                                </template>
                            </b-table>
                            <b-row align-h="center">
                                <b-pagination
                                    v-model="tableData.currentPage"
                                    :total-rows="rows"
                                    :per-page="tableData.resultsPerPage"
                                    aria-controls="ManageTemplate"
                                ></b-pagination>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-modal id="createLocationTemplate-modal" v-model="displayCreateLocationTemplate" size="xl" >
            <addLocationTemplate @toggleAddLocationTemplate="toggleAddLocationTemplate" @onLocationTemplateSaved="onLocationTemplateSaved" />
        </b-modal>
        <b-modal id="locationTemplateUpdate-modal" v-model="displayUpdateLocationTemplate" size="xl">
            <locationTemplateUpdate  @refreshTableAfterChanges="refreshTableAfterChanges"  :selectedLocationTemplate="selectedLocationTemplate"/>
        </b-modal>
    </div>
</template>



<script>

    import api from "@/api";
    import { mapMutations } from "vuex";
    import { BModal } from 'bootstrap-vue';

    import AddLocationTemplate from "./AddLocationTemplate.vue";
    import LocationTemplateUpdate from "./LocationTemplateUpdate.vue";

    export default{
        name: "ManageTemplate",
        url:"",
        components: {
            BModal,
            AddLocationTemplate,
            LocationTemplateUpdate       
        },
        data: () => ({
            displayCreateLocationTemplate: false,
            displayUpdateLocationTemplate: false,
           tableData:{
                reslutsPerPages: 10,
                currentPage: 1,
                dataSource: [],
                isLoading: true,
                tableColumns: [
                    {
                    label: 'Location',
                    key: 'location.name',
                    sortable: true,
                    tdClass: '',
                    },
                    {
                    label: 'Document Template',
                    key: 'documentTemplate.name',
                    sortable: true,
                    tdClass: '',
                    },
                    
                    {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                    },

                ],
           },
            locationsTemplate:[],
            searchTerm: '',
            selectedLocationTemplate: null
        }),

        beforeMount(){
            this.setBreadcrumb([
            {
                text: 'Location Template'
            },
            ]);
            
            this.getLocationTemplateList();
        },

        methods:{
            ...mapMutations('breadcrumbService', ['setBreadcrumb']),
            
            getLocationTemplateList(){
                api.getLocationTemplateList()
                    .then((data)=>{
                        
                        this.tableData.dataSource = data;
                        this.locationsTemplate = data;
                        this.tableData.isLoading = false;
                        
                    })
                    .catch((error)=>{
                        console.error(error);
                    });
            },


            search() {
                this.tableData.dataSource = this.locationsTemplate.filter((x) => 
                    x.location.name.toLowerCase().includes(this.searchTerm.toLowerCase())
                );  
            },

            toggleAddLocationTemplate() {
                this.displayCreateLocationTemplate = !this.displayCreateLocationTemplate;
            },
            onLocationTemplateSaved(locationsTemplate){
                this.locationsTemplate.unshift(locationsTemplate);
                this.tableData.dataSource = this.locationsTemplate;
                this.displayCreateLocationTemplate = !this.displayCreateLocationTemplate;
            },

            toggleLocationTemplateUpdate(locationTemplate) {
                this.displayUpdateLocationTemplate = !this.displayUpdateLocationTemplate;
                this.selectedLocationTemplate = locationTemplate;
                this.tableData.dataSource = this.locationsTemplate;
            },

            refreshTableAfterChanges(updatedLocationTemplate){
                const indexOfUpdatedItem = this.locationsTemplate.findIndex(item=> item.id === updatedLocationTemplate.id);
                if (indexOfUpdatedItem !== -1) { 
                    this.tableData.dataSource.splice(indexOfUpdatedItem,1);
                    this.locationsTemplate.unshift(updatedLocationTemplate);
                    this.tableData.dataSource = this.locationsTemplate;                
                }                
                this.displayUpdateLocationTemplate = !this.displayUpdateLocationTemplate;
            }

        },
        computed:{
            rows(){
                return this.tableData.dataSource.length
            },
        },
    }
</script>

<style scoped>

    .modal-body {
    padding: 0px;
    }

    h4{
        text-align: left;
    }
    h5{
        text-align: left;
    }

    @media (min-width: 992px) 
    {
        .modal-lg, .modal-xl {
            max-width: 1200px !important;

        }
    }

</style>