//import { update } from '@microsoft/sp-lodash-subset';

const axios = require('axios');


let nonAuthGetInstance = axios.create({
    method: "get"
});

let nonAuthPostInstance = axios.create({
    method: "post",
    headers:
        {
            'Content-Type': 'application/json'
        }
});
let nonAuthPutInstance = axios.create({
    method: "put",
    headers:
        {
            'Content-Type': 'application/json'
        }
});

export default {
    Url: "https://localhost:44383",
    //Associated Entity

    //Vendor
    getVendorList(s, e) {
        nonAuthGetInstance({

            url: this.Url + "/Vendor"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Purchase Order");
            });
    },
    getCustomerList(s, e) {
        nonAuthGetInstance({

            url: this.Url + "/Customer"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Purchase Order");
            });
    },
    getFailedEmailList(startDate, endDate, s, e) {
        nonAuthGetInstance({

            url: this.Url + "/Email?startDate="+startDate+"&endDate="+endDate
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Failed Email List");
            });
    },
    AddClientEmailAddress(data, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/Customer/"+data.entityId+"/Email?documentType="+data.documentType+"&email="+data.person.email+"&name="+data.person.name
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    getContactsByCustomerByID(customerId, s, e) {
        nonAuthGetInstance({
            url: this.Url + "/Customer/" + customerId +"/Email?documentType=ClientBookinForm",

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    UpdateCustomerEmailAddress(data, s, e) {
        nonAuthGetInstance({
            url: this.Url + "/Customer/"+data.entityId+"/Email/Update/"+data.contact.id+"?email="+data.contact.emailAddress+"&fullName="+data.contact.fullname
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    DeleteCustomerEmailAddress(data, s, e) {
        nonAuthGetInstance({
            url: this.Url + "/Customer/"+data.entityId+"/Email/Delete/"+data.contact.id
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    getDefaultSchedule(schedule,s,e){
        nonAuthGetInstance({
            url: this.Url + "/CronManager?id="+schedule.id+'&documentType='+schedule.documentType,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Purchase Order");
            });
    },
    updateDefaultSchedule(schedule,s,e){
        nonAuthPutInstance({
            url: this.Url + "/CronManager",
            data:schedule
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Purchase Order");
            });
    },
    addDefaultSchedule(schedule,s,e){
        nonAuthPostInstance({
            url: this.Url + "/CronManager",
            data:schedule
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Purchase Order");
            });
    },

    getPO(vendor,startDate, endDate,s, e) {
        nonAuthGetInstance({

            url: this.Url + "/PurchaseOrder/GetDocument?vendorId="+vendor+"&startDate="+startDate+"&endDate="+endDate
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Purchase Order");
            });
    },
    getDocumentMetaByDate(startDate, endDate ,s,e){
        nonAuthGetInstance({

            url: this.Url + "/PurchaseOrder/GetDocumentMetaList?startDate="+startDate+"&endDate="+endDate
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error);
            });
    },
    getVendorsByPoDate(startDate, endDate, s, e) {
        nonAuthGetInstance({

            url: this.Url + "/PurchaseOrder/ListVendorsForDate?startDate="+startDate+"&endDate="+endDate
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error);
            });
    },
    AddVendorEmailAddress(data, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/Vendor/"+data.entityId+"/Email?documentType="+data.documentType+"&email="+data.person.email+"&name="+data.person.name
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    UpdateVendorEmailAddress(data, s, e) {
        nonAuthGetInstance({
            url: this.Url + "/Vendor/"+data.entityId+"/Email/Update/"+data.contact.id+"?email="+data.contact.emailAddress+"&fullName="+data.contact.fullname
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    DeleteVendorEmailAddress(data, s, e) {
        nonAuthGetInstance({
            url: this.Url + "/Vendor/"+data.entityId+"/Email/Delete/"+data.contact.id
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    UpdateEmailRecipient(data, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/Email/Update",
            data: data
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    ResendEmail(messageId, s, e) {
        nonAuthGetInstance({
            url: this.Url + "/Email/Resend/"+messageId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    DeleteEmail(emailId, s, e) {
        nonAuthGetInstance({
            url: this.Url + "/Email/Delete/"+emailId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    getPoPDF(orderDocumentId, s, e) {
        nonAuthGetInstance({

            url: this.Url + "/PurchaseOrder/GeneratePdf?orderDocumentId="+orderDocumentId,
            responseType:'blob'
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to download Purchase Order");
            });
    },
    getPoMeta(vendor,startDate, endDate, s, e) {
        nonAuthGetInstance({
            url: this.Url + "/PurchaseOrder/GetDocumentMeta?vendorId="+vendor+"&startDate="+startDate+"&endDate="+endDate,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to download Purchase Order");
            });
    },
    GetRecipients(document, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/PurchaseOrder/Email/Recipients",
            data:document
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    SendPoToVendor(document, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/PurchaseOrder/Email/Vendor",
            data:document
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    SendPoToLCS(document, s, e) {
        nonAuthPostInstance({
            url: `${this.Url}/PurchaseOrder/Email/LCS?asPdf=true`,
            data: document
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    SendPoToCustom(data, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/PurchaseOrder/Email/Custom",
            data:data
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    RejectPo(document, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/PurchaseOrder/Email/Reject",
            data: document
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    getVendorByID(vendorId, s, e) {
        nonAuthGetInstance({
            url: this.Url + "/Vendor/GetByID?id="+vendorId,

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    getContactsByVendorByID(data, s, e) {
        nonAuthGetInstance({
            url: this.Url + "/Vendor/" + data.entityId+"/Email?documentType="+data.documentType,

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    getCustomerListForDate(startDate, endDate, s, e){
        nonAuthGetInstance({
            url: this.Url + "/ClientBookingForm/GetCustomersForDate?startDate="+startDate+"&endDate="+endDate,

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    getLocationList(s, e){
        nonAuthGetInstance({
            url: this.Url + "/ClientBookingForm/Locations",

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    getCustomer(customerKey,s,e){
        nonAuthGetInstance({
            url: this.Url + "/ClientBookingForm/GetCustomer?customerId="+customerKey,

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    getCustomerBulkOrders(customerKey, startDate, endDate,s,e){
        nonAuthGetInstance({
            url: this.Url + "/ClientBookingForm/GetCustomerBulkOrders?customerId="+customerKey+"&startDate="+startDate+"&endDate="+endDate,

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    getCustomerBulkOrdersMeta(customerId,startDate, endDate, s,e){
        nonAuthGetInstance({
            url: this.Url + "/ClientBookingForm/GetCustomerBulkOrdersMeta?customerId="+customerId+"&startDate="+startDate+"&endDate="+endDate,

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    getCustomerBookingForm(orderId,startDate, endDate ,s,e){
        nonAuthGetInstance({
            url: this.Url + "/ClientBookingForm/GetClientBookingForm?orderId="+orderId+"&startDate="+startDate+"&endDate="+endDate,

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    getCustomerBookingFormMeta(orderId, startDate, endDate, s,e){
        nonAuthGetInstance({
            url: this.Url + "/ClientBookingForm/GetClientBookingFormMeta?orderId="+orderId+"&startDate="+startDate+"&endDate="+endDate,

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    RejectCbf(document, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/ClientBookingForm/Email/Reject" ,
            data:document
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    SendCbfToLCS(bookingForm, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/ClientBookingForm/Email/LCS",
            data:bookingForm

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    GetClientBookingFormRecipients(bookingForm, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/ClientBookingForm/Email/Recipients",
            data:bookingForm

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    SendCbfToCustomer(bookingForm, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/ClientBookingForm/Email/Client",
            data:bookingForm

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    SendCbfToCustom(document, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/ClientBookingForm/Email/Custom",
            data:document

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    getCustomerBookingFormPDF(documentId, s, e) {
        nonAuthGetInstance({
            url: this.Url + "/ClientBookingForm/GeneratePDF?documentId="+documentId,
            responseType:'blob'
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
        getLc(orderNr,s, e) {
            nonAuthGetInstance({

                url: this.Url + "/LoadConfirmation/GetDocument?orderNr="+orderNr
            })
                .then(function (resp) {
                    s(resp.data);
                })
                .catch(function (error) {
                    console.error(error);
                    e("Failed to load Purchase Order");
                });
        },
    getVendorLcBulkOrders(vendorId,startDate, endDate,s, e) {
        nonAuthGetInstance({

            url: this.Url + "/LoadConfirmation/GetVendorBulkOrders?vendorId="+vendorId+"&startDate="+startDate+"&endDate="+endDate
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Purchase Order");
            });
    },
    getVendorLcBulkOrdersMeta(vendorId,startDate, endDate,s, e) {
        nonAuthGetInstance({

            url: this.Url + "/LoadConfirmation/GetVendorBulkOrdersMeta?vendorId="+vendorId+"&startDate="+startDate+"&endDate="+endDate
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Purchase Order");
            });
    },
    getLcDocumentMetaByDate(startDate, endDate,s,e){
        nonAuthGetInstance({

            url: this.Url + "/LoadConfirmation/GetDocumentMetaList?startDate="+startDate+"&endDate="+endDate
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error);
            });
    },
    getVendorsByLcDate(startDate, endDate, s, e) {
        nonAuthGetInstance({

            url: this.Url + "/LoadConfirmation/ListVendorsForDate?startDate="+startDate+"&endDate="+endDate
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error);
            });
    },
    getLcPDF(documentId, s, e) {
        nonAuthGetInstance({

            url: this.Url + "/LoadConfirmation/GeneratePdf?documentId="+documentId,
            responseType:'blob'
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to download Purchase Order");
            });
    },
    getLcMeta(orderNr, s, e) {
        nonAuthGetInstance({
            url: this.Url + "/LoadConfirmation/GetDocumentMeta?orderNr="+orderNr,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to download Purchase Order");
            });
    },
    SendLcToVendor(document, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/LoadConfirmation/Email/Vendor",
            data:document
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    SendLcToLCS(document, s, e) {
        nonAuthPostInstance({
            url: `${this.Url}/LoadConfirmation/Email/LCS?asPdf=true`,
            data:document
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    SendLcToCustom(data, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/LoadConfirmation/Email/Custom",
            data:data
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },

    RejectLc(document, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/LoadConfirmation/Email/Reject",
            data: document
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    //LocationTemplate
    getLocationTemplateList() {
        return new Promise((s,e)=>{
            nonAuthGetInstance({
                url: this.Url + "/LocationTemplate/GetAllLocationTemplates",
            })
                .then(function (resp) {
                    s(resp.data);
                })
                .catch(function (error) {
                    console.error(error);
                    e("Failed to load LocationTemplate");
                });
        })
        
    },

    getLocationTemplates(s, e) {
        nonAuthGetInstance({

            url: this.Url + "/LocationTemplate/GetAllLocationTemplates"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load LocationTemplates");
            });
    },

    getLocationTemplate(Id, s, e) {
        nonAuthGetInstance({

            url: this.Url + "/LocationTemplate/GetLocationTemplatesById/"+Id
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load LocationTemplates");
            });
    },

    CreateLocationTemplate(locationTemplate,s,e){
        nonAuthPostInstance({
            url: this.Url + "/LocationTemplate/CreateLocaionTemplate",
            data:locationTemplate
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to linke location with template");
            });
    },

    UpdateLocationTemplate(locationTemplate, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/LocationTemplate/UpdateLocaionTemplate",
            data: locationTemplate
        })
        .then(function (resp) {
            s(resp.data);
         })
        .catch(function (error) {
            console.error(error);
            e("Failed to update template");
        });
    },


    //DocumentTemplate
    getDocumentTemplateList(s, e) {
        nonAuthGetInstance({

            url: this.Url + "/DocumentTemplate/GetAllDocumentsTemplates"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Location Template");
            });
    },

    //ContactRoute
    getAllContactRoutes(s,e){
        nonAuthGetInstance({
        
            url: this.Url + "/ContactRoute/GetAllContactRoutes"

        })
        .then(function (resp) {
            s(resp.data);
        })
        .catch(function (error) {
            console.error(error);
            e("Failed to load Contact Route");
        });
    },

    // createContactRoute(data,s,e){
    //     nonAuthPostInstance({
    //         url: this.Url + "/Customer/"+data.entityId+"/Email/Update/"+data.contact.id+"?email="+data.contact.emailAddress+"&fullName="+data.contact.fullname
    //     })
    //     .then(function (resp) {
    //         s(resp.data);
    //     })
    //     .catch(function (error) {
    //         console.error(error);
    //         e("Failed to create Contact Route");
    //     });
    // },

    createContactRoute(data,s,e){
        nonAuthPostInstance({
            url: this.Url + "/ContactRoute/CreateContactRoutes",
            data: data
        })
        .then(function (resp) {
            s(resp.data);
        })
        .catch(function (error) {
            console.error(error);
            e("Failed to create Contact Route");
        });
    },

    updateContactRoute(data,s,e){
        nonAuthPutInstance({
            url: this.Url + "/ContactRoute/UpdateContactRoute",
            data: data
        })
        .then(function (resp) {
            s(resp.data);
        })
        .catch(function (error) {
            console.error(error);
            e("Failed to update Contact Route");
        });
    }
}

