<template>
    <div class="document-generation" id="PurchaseOrderList">
        <div class="page">
            <b-row>
                <H1> Default Schedulers </H1>
            </b-row>
            <b-row>
                <H3> Document Types</H3>
            </b-row>
            <b-row v-if="docTypes.length != 0" class="w-100">
                <b-col class="p-0">
                    <b-form-select v-model="selectedDocument" :options="docTypes"></b-form-select>
                </b-col>
            </b-row>

            <div v-if="selectedDocument != null">
                <div v-if="selectedDocument == 'ClientBookingFormDocument'">
                    <b-row class="w-100 mt-3">
                        <H3> Schedule Types</H3>
                    </b-row>
                    <b-row class="w-100">
                        <b-col class="p-0">
                            <b-form-select v-model="selectedScheduleSubtype" :options="ScheduleSubTypes"></b-form-select>
                        </b-col>
                    </b-row>
                </div>

                <b-row v-if="selectedScheduleSubtype != null || selectedDocument != 'ClientBookingFormDocument'">
                    <b-input-group class="mt-3 mb-3 w-100" :prepend="selectedDocument.idType">
                        <b-form-input type="text" v-model="searchInput"></b-form-input>
                        <b-input-group-append>
                            <b-button @click="search">Search
                                <b-icon-search />
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-row>
                <b-row>
                    <b-col class="p-0">
                        <b-list-group v-for="item in filteredList" :key="'ID:' + item.id" @click="onSelectItem(item)">
                            <b-list-group-item>
                                {{ item.name }}
                            </b-list-group-item>
                        </b-list-group>
                    </b-col>
                </b-row>
            </div>
            <b-row v-if="loading">
                <b-spinner></b-spinner>
            </b-row>
            <b-modal v-if="selectedItem != null" size="xl" id="ScheduleSelect" centered title="Scheduler Select"
                :busy="true">
                <b-row>
                    <span>Schedule for {{ selectedItem.name }} on document
                        {{ selectedDocument.text }}</span>
                </b-row>
                <b-row>
                    <b-input v-model="selectedCron"></b-input>
                </b-row>
                <template #modal-footer="{}">
                    <b-button size="sm" variant="success" @click="saveCron(selectedCron)">
                        OK
                    </b-button>
                    <b-button size="sm" variant="danger" @click="cancel(selectedCron)">
                        Cancel
                    </b-button>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import api from "@/api";
import sortList from "@/sortAlgorithm";

export default {
    name: "DefaultScheduleList",
    data: () => ({
        date: "",
        docTypes: [
            {
                text: "Purchase Order Document",
                value: "PurchaseOrderDocument",
                idType: "Vendor",
            },
            {
                text: "Customer Booking Form Document",
                value: "ClientBookingFormDocument",
                idType: "Client",
            },
            {
                text: "Load Confirmation Document",
                value: "LoadConfirmationDocument",
                idType: "Vendor",
            },
        ],
        ScheduleSubTypes: [
            {
                text: "Manage Customer",
                value: "Customers",
                idType: "Customers"
            },
            {
                text: "Manage Location",
                value: "Location",
                idType: "Location",
            }
        ],
        selectedScheduleSubtype: null,
        loading: false,
        customerId: null,
        customer: null,
        searchInput: "",
        filteredList: [],
        documentMeta: [],
        selectedDocument: null,
        selectedItem: null,
        selectedCron: null,
        mainList: [],
        isCustomerSelected: false
    }),
    methods: {
        ...mapMutations("breadcrumbService", ["setBreadcrumb"]),
        GetVendorList() {
            api.getVendorList(
                (data) => {
                    this.mainList = data;
                    this.filteredList = [...this.mainList];
                    sortList(this.filteredList);
                },
                () => {
                    this.makeToast(
                        "Vendor List",
                        "Failed to load Vendor list",
                        "warning"
                    );
                }
            );
        },
        GetClientList() {
            api.getCustomerList(
                (data) => {
                    this.mainList = data;
                    this.filteredList = this.mainList;
                    sortList(this.filteredList);
                },
                () => {
                    this.makeToast(
                        "Vendor List",
                        "Failed to load Client list",
                        "warning"
                    );
                }
            );
        },
        GetLocationsList() {
            api.getLocationList(
                (data) => {
                    this.mainList = data;
                    this.filteredList = this.mainList;
                    sortList(this.filteredList);
                },
                () => {
                    this.makeToast(
                        "Location List",
                        "Failed to load Client location list",
                        "warning"
                    );
                }
            );
        },
        search() {
            this.filteredList = this.mainList.filter((x) =>
                x.name.toLowerCase().includes(this.searchInput.toLowerCase())
            );
        },
        onSelectItem(value) {
            this.selectedItem = value;

            var schedule = {
                id: this.selectedItem.id,
                documentType: this.selectedScheduleSubtype == 'Location' ? this.selectedScheduleSubtype : this.selectedDocument,
                cron: "",
            };

            debugger

            let self = this;
            var success = (data) => {
                if (data) {
                    self.selectedCron = data.cron;
                }
                else {
                    if (self.selectedScheduleSubtype === "Location" || self.selectedDocument === "ClientBookingFormDocument") {
                        self.selectedCron = "0 7-19/2 * * MON,TUE,WED,THU,FRI,SAT,SUN";
                    } else if (self.selectedDocument === "LoadConfirmationDocument") {
                        self.selectedCron = "0 */2 * * 1-7";
                    } else if (self.selectedDocument === "PurchaseOrderDocument") {
                        self.selectedCron = "0 9 * * MON,TUE,WED,THU,FRI";
                    }

                }

                this.$bvModal.show("ScheduleSelect");
            };

            var error = (err) => {
                console.log(err)
            };

            api.getDefaultSchedule(schedule, success, error);
        },
        saveCron(cron) {
            var schedule = {
                id: this.selectedItem.id,
                documentType: this.selectedScheduleSubtype == 'Location' ? this.selectedScheduleSubtype : this.selectedDocument,
                cron: cron,
            };

            let self = this;

            var success = function (data) {
                self.cron = data.cron;
                self.$bvModal.hide("ScheduleSelect");
                self.makeToast(
                    "Scheduler",
                    "Schedule Added",
                    "success"
                );
                self.selectedItem = null;
            };
            var error = function (err) {
                alert("Could not add Schedule:" + err);
                self.makeToast(
                    "Scheduler",
                    "Could not add Schedule",
                    "warning"
                );
                self.cron = null;
            };
            api.addDefaultSchedule(schedule, success, error);
        },
        cancel() {
            this.cron = null;
            this.$bvModal.hide("ScheduleSelect");
        },
    },
    created() {
        this.setBreadcrumb([
            {
                text: "Settings",
            },
            {
                text: "Default Schedule",
                active: true,
            },
        ]);
    },
    watch: {
        selectedDocument(newValue) {
            var item = this.docTypes.find((x) => x.value === newValue);
            this.selectedScheduleSubtype = null;
            this.searchInput = "";
            this.mainList = [];
            this.filteredList = [];
            if (item.idType == "Vendor") {
                this.GetVendorList();
            }
        },
        selectedScheduleSubtype(newValue) {
            var item = this.ScheduleSubTypes.find((x) => x.value === newValue);
            this.searchInput = "";
            this.mainList = [];
            this.filteredList = [];
            switch (item.idType) {
                case "Customers":
                    this.GetClientList();
                    break;
                case "Location":
                    this.GetLocationsList();
                    break;
            }
        }
    },
};
</script>
<style>
.page {
    background-color: whitesmoke;
    width: 96%;
    height: 100%;
    padding: 5% 2%;
    margin: 0 auto;
}
</style>