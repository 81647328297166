<template>
  <div class="document-generation"
       id="LoadConfirmationList">
    <div class="page">
      <b-row>
        <H1> Load Confirmations </H1>
      </b-row>
      <b-row>
        <H2> Vendor List</H2>
      </b-row>
      <b-row>
        <date-range-picker :defaultDate="defaultDate" @onDateSelected="onDateSelected" />
      </b-row>
      <div v-if="vendorList.length != 0 && !loading">
        <b-row>
          <b-input-group class="mb-3 w-100"
                         prepend="Vendor">
            <b-form-input type="text"
                          v-model="searchInput"></b-form-input>
            <b-input-group-append>
              <b-button @click="search">Search <b-icon-search />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-row>
        <b-row>
          <b-col class="p-0">
            <b-list-group v-for="vendor in filteredList"
                          :key="'Vendor' + vendor.id">
              <b-list-group-item :class="getDocumentState(vendor.id)"
                                 :to="{ name: 'loadConfirmationOrders', params: { Vendor: vendor.id, StartDate: startDate, EndDate: endDate } }">
                <!--                <b-icon :icon="statusIcon(getDocumentState(vendor.id))"-->
                <!--                        :variant="statusIconVariant(getDocumentState(vendor.id))">-->
                <!--                </b-icon>-->
                {{ vendor.name }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </div>
      <b-row v-if="vendorList.length == 0 && !loading">
        <b-col class="p-0">
          <b-list-group>
            <b-list-group-item>No Vendors for this date</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <div v-if="loading"
           class="d-flex justify-content-center pt-4">
        <b-row>
          <b-spinner></b-spinner>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";
import 'flatpickr/dist/flatpickr.css';
import DateRangePicker from '../../components/DateRangePicker.vue'

export default {
  name: "LoadConfirmationList",
  data: () => ({
    startDate: "",
    endDate: "",
    defaultDate: [],
    vendorList: [],
    loading: false,
    searchInput: "",
    filteredList: [],
    documentMeta: []
  }),
  components: {
    DateRangePicker
  },
  computed: {},
  methods: {
    ...mapMutations("breadcrumbService", ["setBreadcrumb"]),
    onDateSelected(dateSelected) {
      this.startDate = dateSelected.startDate;
      this.endDate = dateSelected.endDate;

      if (this.startDate && this.endDate) {
        this.$router.push({ path: `/LoadConfirmation/${this.startDate}/${this.endDate}` })
      }
    },
    getVendorList() {
      this.loading = true;
      api.getVendorsByLcDate(this.startDate, this.endDate, (data) => {
        this.vendorList = data
        this.filteredList = this.vendorList

        api.getLcDocumentMetaByDate(this.startDate, this.endDate, (doc) => {
          this.documentMeta = doc
          this.loading = false;
        }, err => {
          console.error(err)
          this.makeToast("Vendor List", "Failed to load Document Meta list for date", "warning")
          this.loading = false;
        })
      },
        (e) => {
          console.error(e)
          this.makeToast("Vendor List", "Failed to load Vendor list for date", "warning")
        })
    },
    search() {
      this.filteredList = this.vendorList.filter(x => x.name.toLowerCase().includes(this.searchInput.toLowerCase()))
    },
    statusIcon(status) {
      if (status === "Accepted") {
        return "patch-check-fill"
      }
      if (status === "Rejected") {
        return "patch-exclamation-fill"
      }
      return "patch-question-fill"
    },
    statusIconVariant(status) {
      if (status === "Accepted") {
        return "success"
      }
      if (status === "Rejected") {
        return "warning"
      }
      return "info"
    },

    getDocumentState(entityId) {
      var doc = this.documentMeta.filter(x => x.entityId == entityId)[0]
      if (doc) {
        switch (doc.status) {
          case 0:
            return "Pending"
          case 1:
            return "Accepted"
          case 2:
            return "Rejected"
        }
      }
      return ""
    }
  },
  created() {
    this.startDate = this.$route.params["StartDate"];
    this.endDate = this.$route.params["EndDate"];

    if (this.startDate && this.endDate) {
      this.defaultDate = [this.startDate, this.endDate];
      
      const dateNavigation = this.startDate === this.endDate ? this.startDate : this.startDate + " to " + this.endDate;
      
      this.setBreadcrumb([
        {
          text: 'Documentations',

        },
        {
          text: 'Load Confirmation',
          to: { name: 'loadConfirmation-list' },
        },
        {
          text: 'Date : ' + dateNavigation,
          active: true
        }

      ])

      this.getVendorList();
    } else {
      this.setBreadcrumb([
        {
          text: 'Documentations',

        },
        {
          text: 'Load Confirmations',
          active: true,
        },

      ])
    }

  }
}
</script>

<style lang="scss" scoped>
.page {
  background-color: whitesmoke;
  width: 1200px;
  padding: 125px 150px;
  margin: 0 auto;
}
</style>