<template>
    <div style="width: 100%;">
        <b-row>
            <b-col>
                    <b-row>
                        <b-col>
                            <h4>Update Template</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="5" class="text-left">
                            <label>Location</label>
                            <b-input v-model="locationTemplate.location.name" :disabled="true">

                            </b-input>
                        </b-col>
                        <b-col cols="4" class="text-left">
                            <label>Template</label>
                            <b-form-select v-model="locationTemplate.documentTemplate" :disabled="isSaving"  >
                                <b-form-select-option v-for="(items, index) in templatName" :key="index" :value="items" > 
                                    {{items.name}}
                                </b-form-select-option>
                            </b-form-select>    
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right">
                            <b-button variant="orange" size="sm"  squared @click="updateLocationTemplate" >{{isSaving ? 'Saved...': "Save" }}</b-button>
                        </b-col>
                    </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import api from "@/api";

    export default{
        name: "updateTemplate",
        data:() =>({
            
            locationTemplate: null,
            templatName: [],
            isSaving: false

        }),
        props: {
            selectedLocationTemplate: Object
        },
        created(){
            console.log(this.selectedLocationTemplate);
            const d = (data) => {
                this.templatName = data;   
            };

            const f = (msg) => {
                console.log(msg);
            };
            api.getDocumentTemplateList(d,f);

            const s = (data) => {
                this.locationTemplate = data;
            };

            const e = (msg) => {
                console.log(msg);

            };

            api.getLocationTemplate(this.selectedLocationTemplate.id, s,e);


        },
        methods:{

            getLocationTemplate(){
                api.getLocationTemplate(this.selectedLocationTemplate.id)
                    .then((data)=>{
                        this.locationTemplate = data;                        
                    })
                    .catch((error)=>{
                        console.error(error);
                    });
            },

            updateLocationTemplate(){
                
                api.UpdateLocationTemplate(this.locationTemplate,
                    ()=>
                    {
                        this.isSaving = false;
                        this.$emit("refreshTableAfterChanges",this.locationTemplate)
                    },
                    (error)=>{
                        console.error(error);
                        
                    }  
                    
                );

            },
            
        }
    }

</script>

<style scoped>

</style>